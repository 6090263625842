import {HttpRequest} from './HttpRequest.class';
import * as HttpStatus from 'http-status-codes';

export class ProductUtils {

    /**
     * Function that valid stock in DB.
     *
     * @param merchant
     * @param dispatch
     * @param update
     *
     * @return boolean
     */
    static async validStock(merchant:string, dispatch:any, update:boolean = true) {
        let store:any = localStorage.getItem('store');
        store = JSON.parse(store);
        const noCustomer = store.merchantParams?.noClient || false;
        let shoppingCart = noCustomer ? (store.cart[noCustomer] ? store.cart[noCustomer] : store.cart[0]) : store.cart[0];
        let data = {productsList: shoppingCart};
        let postPath = 'product/validStock';
        let validResponse:any;
        let flag = true;

        if (shoppingCart.total > 0) {
            let request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
            const rulesRequest = await request.get('rule');

            if (rulesRequest.status === HttpStatus.OK) {
                const dataResponse = rulesRequest.data;
                let validStockPath = '';
                dataResponse.map((current: any) => {
                    if (current.name === 'validStockPath') {
                        request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
                        validStockPath = current.value.path;
                    }
                });

                if (validStockPath != '') {
                    postPath = validStockPath;
                    data = Object.assign(data, {noClient: noCustomer});
                }
            }

            validResponse = await request.post(data, postPath);
            if (validResponse.status === HttpStatus.CREATED) {
                if (update) {
                    ProductUtils.updateCartStock(validResponse.data.productsToUpdate, dispatch);
                }

                flag = validResponse.data.flag;
            }
        }

        return flag;
    }

    /**
     * Update cart
     *
     * @param productsToUpdate
     * @param dispatch
     */
    static updateCartStock(productsToUpdate:{}, dispatch:any) {
        for (const [productId, productQuantity] of Object.entries(productsToUpdate)) {
            dispatch({
                type: 'ADD-CARD',
                payload: {
                    [productId]: productQuantity
                }
            });
        }
    }
}
