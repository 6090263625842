export default (shipmentValues:any, shipmentErrors:any, updateOrder:any = (data:any) => {}, current:number) => {
  if (!Object.keys(shipmentErrors).length && current) {
    let addressData = {};
    let clientProfileData = {email: shipmentValues.email.trim()};
    
    if (shipmentValues.DNI) {
      clientProfileData = Object.assign(clientProfileData, {
        DNI: shipmentValues.DNI,
        phoneNumber: shipmentValues.phoneNumber,
        lastName: shipmentValues.lastName.trim(),
        firstName: shipmentValues.firstName.trim()
      });
    }
    
    if (shipmentValues.id) addressData = {id: shipmentValues.id};
    
    if (shipmentValues.address) {
      addressData = Object.assign(addressData, {
        city: shipmentValues.city,
        state: shipmentValues.state,
        district: shipmentValues.district,
        postalCode: shipmentValues.postalCode,
        address: shipmentValues.address.trim()
      });
    }
    
    const shipmentData = {
      delivered: {
        shipment: {
          address: addressData,
          clientProfile: clientProfileData
        }
      }
    };
    
    updateOrder(shipmentData);
  }
}