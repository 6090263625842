import {ShipmentCOInterface} from './ShipmentCOInterface';

export function ShipmentCOValidators(values:ShipmentCOInterface):ShipmentCOInterface {
  let errors:ShipmentCOInterface = {};

  if (!values.DNI || values.DNI.trim() == '') {
    errors.DNI = 'SHIPMENT__FORM_ERROR_DNI';
  } else if(!/^((\d{8})|(\d{10})|(\d{11})|(\d{6}-\d{5}))?$/im.test(values.DNI.trim())) {
    errors.DNI = 'SHIPMENT__FORM_INCORRECT_DNI_CO';
  }

  if (!values.city || values.city.trim() == '') {
    errors.city = 'SHIPMENT__FORM_ERROR_CITY';
  }

  if (!values.state || values.state.trim() == '') {
    errors.state = 'SHIPMENT__FORM_ERROR_DEPARTMENT';
  }

  if (!values.phoneNumber || values.phoneNumber.trim() == '') {
    errors.phoneNumber = 'SHIPMENT__FORM_ERROR_PHONE';
  } else if (values.phoneNumber.length <= 8) {
    errors.phoneNumber =  'SHIPMENT__FORM_INCORRECT_PHONE';
  }

  if (!values.address || values.address.trim() == '') {
    errors.address = 'SHIPMENT__FORM_ERROR_ADDRESS';
  } else if (/[!@$%~^*&()_+:"<>;{}?=||/]/im.test(values.address.trim())) {
    errors.address = 'SHIPMENT__FORM_INCORRECT_ADDRESS';
  }

  if (!values.email || values.email.trim() == '') {
    errors.email = 'SHIPMENT__FORM_ERROR_EMAIL';
  } else if (!/^[a-zA-Z0-9._`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/im.test(values.email.trim())) {
    errors.email = 'SHIPMENT__FORM_INCORRECT_EMAIL';
  }

  if (!values.firstName || values.firstName.trim() == '') {
    errors.firstName = 'SHIPMENT__FORM_ERROR_FIRSTNAME';
  } else if (!/^(?:[A-ZÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙ][a-záéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)(\s+[a-áéíóúáÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)?$/im.test(values.firstName.trim())) {
    errors.firstName = 'SHIPMENT__FORM_INCORRECT_FIRSTNAME';
  }

  if (!values.lastName  || values.lastName.trim() == '') {
    errors.lastName = 'SHIPMENT__FORM_ERROR_LASTNAME';
  } else if (!/^(?:[A-ZÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙ][a-záéíóúÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)(\s+[a-áéíóúáÁÉÍÓÚÂÊÎÔÛâêîôûàèìòùÀÈÌÒÙ]+)?$/im.test(values.lastName.trim())) {
    errors.lastName = 'SHIPMENT__FORM_INCORRECT_LASTNAME';
  }

  return errors;
}