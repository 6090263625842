import './PromotionScreen.css';

import {Tooltip} from 'antd';
import {TagsFilled} from '@ant-design/icons';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Context} from '../../Store';
import {HttpRequest} from '../../../services/HttpRequest.class';
import * as HttpStatus from 'http-status-codes';
import {ProductItem} from '../stock/ProductItem';
import {ScrollHandlerWrapper} from '../../scrollHandler/ScrollHandler';
import {LeftOutlined,RightOutlined} from '@ant-design/icons';
import {NewProductModal} from '../stock/AMPMFront/NewProductModal';
import {AMPMPurchaseProduct} from '../actions/AMPMPurchaseProduct';
import {Utils} from '../../../services/Utils.class';


export function PromotionScreen (props: {
  promos:any,
  visible:boolean,
  merchant:string,
  loadingPromo:any,
  editing?:boolean,
  storeId:any,
  viewChange:boolean,
  changePercent:Function,
  setPromotionScreen:Function,
  updateStateStock: Function,

}) {
  const [state, dispatch] = useContext(Context);
  const isMobile = window.innerWidth<950
  const Products = state.products['ampm']
  const [show, setShow] = useState(false);
  const customerId = state.merchantParams?.identification
  const [allPromo, setAllPromo] = useState(false)
  const [allPromoPagination, setAllPromoPagination] = useState<number>(0)
  const [promoProducts, setPromoProducts] = useState([])
  const [BSProducts, setBSProducts] = useState<any[]>([])
  const [reducedPromo, setReducedPromo] = useState<any[]>([])
  const [selectedProduct, setSelectedProduct] = useState<any>({})
  const name = state.merchantParams.name?.split(" ")[0].charAt(0).toUpperCase() + state.merchantParams.name?.split(" ")[0].slice(1) || "";
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];
  const [promotionInfo, setPromotionInfo] = useState({
    message: '',
    image: '',
  });

  useEffect(() => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const timeInMinutes = hours * 60 + minutes;

    let message = '';
    let image = '';

    if (timeInMinutes >= 181 && timeInMinutes <= 600) { // 03:01 am - 10:00 am
      message = `${name}, el desayuno ya está servido.`;
      image = 'https://i.postimg.cc/V6gZT5TL/Desayuno.jpg';
    } else if (timeInMinutes >= 601 && timeInMinutes <= 690) { // 10:01 am - 11:30 am
      message = `${name}, disfrutá de meriendas saludables.`;
      image = 'https://i.postimg.cc/vTNTf7tp/merienda.jpg';
    } else if (timeInMinutes >= 691 && timeInMinutes <= 840) { // 11:31 am - 02:00 pm
      message = `${name}, tu almuerzo está listo.`;
      image = 'https://i.postimg.cc/5yZBkVx2/almuerzo.jpg';
    } else if (timeInMinutes >= 841 && timeInMinutes <= 1020) { // 02:01 pm - 05:00 pm
      message = `${name}, disfrutá de un café.`;
      image = 'https://i.postimg.cc/h4643jq0/Cafe.jpg';
    } else if ((timeInMinutes >= 1021 && timeInMinutes <= 1440) || (timeInMinutes >= 0 && timeInMinutes <= 180)) { // 05:01 pm - 03:00 am
      const days = currentTime.getDay();
      if (days >= 0 && days <= 3) {
        message = `${name}, te ayudamos con la cena.`;
        image = 'https://i.postimg.cc/tTLTPCqD/cena.jpg';
      } else {
        message = `${name}, tenemos todo para tu fin de semana.`;
        image = 'https://i.postimg.cc/pL2dKSvN/Celebraci-n.jpg';
      }
    }

    setPromotionInfo({
      message,
      image,
    });
  }, [name]);

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[allPromoPagination])

  useEffect(()=>{getBSproducts()},[props.storeId])

  const filteredPromos = props.promos.filter(e=>e.models[0].imgId!==''
    && e.models[0].quantity>0&&e.models[0].promo.percent>1)

  const getUnitaryIVA = (product:any) => {
    return Math.floor(product?.sku?.taxAsInt / 100);
  };


  const getBSproducts = async () => {
      if (customerId){
        const request = new HttpRequest(`https://api.${process.env.REACT_APP_APP_DOMAIN}` || '');
        const BSrequest = await request.get(`ampmProduct/bestSelling/${props.storeId}/${customerId}`);
        if (BSrequest.status === HttpStatus.OK) {
         const filteredProducts = BSrequest.data;
          setBSProducts(filteredProducts);
          props.updateStateStock(filteredProducts);
       }
      }
  }

  const ImagesTemp = (contentRef:any, isClicking:any) => {
    const images = [
      "https://tb-static.uber.com/prod/image-proc/processed_images/e879c9b1abe025d78747817506e27dec/93b5fd796682c6d5302cd5bec164fe90.jpeg",
      "https://ampm.cr/wp-content/uploads/2023/03/VODKAMEZCLADOR.png",
      "https://ampm.cr/wp-content/uploads/2023/03/CENTENAROCOCA-COLA-2L.png",
      "https://ampm.cr/wp-content/uploads/2023/03/HOLLANDIAPATACONESDUCAL.png",
      "https://ampm.cr/wp-content/uploads/2023/03/IMPERIALAMPM-SEMILLAS.png",
      "https://ampm.cr/wp-content/uploads/2023/03/DP-TERUMBA-PATACONES.png"
    ]
    if(isMobile){
      return(
        <div className={'PromotionScreen-carousel'}>
          {images.map((current: any, index: number)=>{
            return(
              <div className={'PromotionScreen-banner'} style={{backgroundImage:`url(${current})`}}/>
            )
          })
          }
        </div>
      )
    }
    else return(
      <div className={'PromotionScreen-carousel'} ref={contentRef}>
        {images.map((current: any, index: number)=>{
          return(
            <div className={'PromotionScreen-banner'} style={{backgroundImage:`url(${current})`}}/>
          )
        })
        }
      </div>
    )
  }

  const PromoCarouselContent = (contentRef:any, isClicking:any) => {
    if(!isMobile){
      return(
        <div className={'PromotionScreen-carousel'} ref={contentRef}>
          <div>
            {show ? <NewProductModal item={selectedProduct.models? selectedProduct : {...selectedProduct, models:[selectedProduct]}} key={selectedProduct.id} visible={show} setShowModal={setShow} product={selectedProduct.models? selectedProduct.models[0]:selectedProduct} products={Products}/> : ''}
          </div>
          {
          filteredPromos.map((current: any, index: number)=>{
              let promoModel = current.models.find((model: any) => model.promo && model.promo.percent !== 0);
              let promoPrice = (current.models[0].price + getUnitaryIVA(current.models[0]))*(cart[promoModel?.id]||1)
            let percent = current.models[0].promo?.percent
            let price = promoPrice * (1+(percent/100))
              if(promoModel) {
                return (
                  <>
                    <div className={'PromotionScreen-Card'}>
                      <div
                        onClick={() => {
                          if (!isClicking) {
                            setSelectedProduct(current);
                            showModal();
                          }
                        }}
                        id={promoModel.imgId}
                        className="PromotionScreen-productImg"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${promoModel.imgId})`
                        }}
                      >
                        <div className={'PromotionScreen-Card-Flag'}><TagsFilled
                          className={'PromotionScreen-TagsFilled'}/>{Math.ceil(percent)}%
                        </div>
                      </div>
                      <div className={'PromotionScreen-textContainer'}>
                        <p className={'PromotionScreen-name'}>{current.name}</p>
                        <div className={'PromotionScreen-priceContainer'}>
                          <><p
                            className={'PromotionScreen-price'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(price )}</p>
                            <p
                              className={'PromotionScreen-promoPrice'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(promoPrice )}</p></>

                        </div>
                      </div>
                      <div className={'PromotionScreen-purchaseCont'}><AMPMPurchaseProduct
                        product={current.models[ 0 ]} hideAvailableStock={false}/></div>
                    </div>
                  </>
                );
              }
            })
          }
          {
            filteredPromos.map((current: any, index: number)=>{
              let promoModel = current.models.find((model: any) => model.promo && model.promo.percent !== 0);
              let promoPrice = (current.models[0].price + getUnitaryIVA(current.models[0]))*(cart[promoModel?.id]||1)
              let percent = Math.ceil(current.models[0].promo.percent)
              let price = (promoPrice * (1+(percent/100)))
              if(promoModel) {
                return (
                  <>
                    <div className={'PromotionScreen-Card'}>
                      <div
                        onClick={() => {
                          if (!isClicking) {
                            setSelectedProduct(current);
                            showModal();
                          }
                        }}
                        id={promoModel.imgId}
                        className="PromotionScreen-productImg"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${promoModel.imgId})`
                        }}
                      >
                        <div className={'PromotionScreen-Card-Flag'}><TagsFilled
                          className={'PromotionScreen-TagsFilled'}/>{Math.ceil(percent)}%
                        </div>
                      </div>
                      <div className={'PromotionScreen-textContainer'}>
                        <p className={'PromotionScreen-name'}>{current.name}</p>
                        <div className={'PromotionScreen-priceContainer'}>
                          <><p
                            className={'PromotionScreen-price'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(price )}</p>
                            <p
                              className={'PromotionScreen-promoPrice'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(promoPrice )}</p></>

                        </div>
                      </div>
                      <div className={'PromotionScreen-purchaseCont'}><AMPMPurchaseProduct
                        product={current.models[ 0 ]} hideAvailableStock={false}/></div>
                    </div>
                  </>
                );
              }
            })
          }
        </div>
      )
    }
    else{
      return(
        <div className={'PromotionScreen-carousel'} >
          <div>
            {show ? <NewProductModal item={selectedProduct} key={selectedProduct.id} visible={show} setShowModal={setShow} product={selectedProduct.models[0]} products={Products}/> : ''}
          </div>
          {
            props.promos?.filter(e=>e.models[0].promoPrice>0 && e.models[0].quantity>0).map((current: any, index: number)=>{
              let promoModel = current.models.find((model: any) => model.promo && model.promo.percent !== 0);
              let promoPrice = (current.models[0].price + getUnitaryIVA(current.models[0]))*(cart[promoModel?.id]||1)
              let percent = Math.ceil(current.models[0].promo.percent)
              let price = (promoPrice * (1+(percent/100)))
              return(
                <>
                  <div className={'PromotionScreen-Card'}>
                     <div
                      id={promoModel}
                      className="PromotionScreen-productImg"
                      onClick={()=>{setSelectedProduct(current);showModal()}}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${promoModel.imgId})`
                      }}
                    >
                      <div className={'PromotionScreen-Card-Flag'}><TagsFilled className={'PromotionScreen-TagsFilled'}/>{Math.ceil(percent)}%</div>
                    </div>
                    <div className={'PromotionScreen-textContainer'}>
                      <p className={'PromotionScreen-name'}>{current.name}</p>
                      <div className={'PromotionScreen-priceContainer'}>
                        <p className={'PromotionScreen-price'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(price)}</p>
                        <p className={'PromotionScreen-promoPrice'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(promoPrice)}</p>
                      </div>
                    </div>
                    <div className={'PromotionScreen-purchaseCont'}><AMPMPurchaseProduct  product={promoModel} hideAvailableStock={false}/></div>

                  </div>
                </>
              )
            })
          }
          {
            props.promos?.filter(e=>e.models[0].promoPrice>0 && e.models[0].quantity==0).map((current: any, index: number)=>{
              let promoModel = current.models.find((model: any) => model.promo && model.promo.percent !== 0);
              let promoPrice = (current.models[0].price + getUnitaryIVA(current.models[0]))*(cart[promoModel?.id]||1)
              let percent = Math.ceil(current.models[0].promo.percent)
              let price = (promoPrice * (1+(percent/100)))
              return(
                <>
                  <div className={'PromotionScreen-Card'}>
                    <div
                      id={promoModel}
                      className="PromotionScreen-productImg"
                      onClick={()=>{setSelectedProduct(current);showModal()}}
                      style={{
                        backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${promoModel.imgId})`
                      }}
                    >
                      <div className={'PromotionScreen-Card-Flag'}><TagsFilled className={'PromotionScreen-TagsFilled'}/>{Math.ceil(percent)}%</div>
                    </div>
                    <div className={'PromotionScreen-textContainer'}>
                      <p className={'PromotionScreen-name'}>{current.name}</p>
                      <div className={'PromotionScreen-priceContainer'}>
                        <p className={'PromotionScreen-price'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(price)}</p>
                        <p className={'PromotionScreen-promoPrice'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(promoPrice)}</p>
                      </div>
                    </div>
                    <div className={'PromotionScreen-purchaseCont'}><AMPMPurchaseProduct  product={promoModel} hideAvailableStock={false}/></div>

                  </div>
                </>
              )
            })
          }
        </div>
      )
    }

  }

  const BSProductContent = (contentRef:any, isClicking:any) => {
        if(!isMobile){
      return(
        <div className={'PromotionScreen-carousel'}  ref={contentRef}>
          {BSProducts.filter(e=> e.models[0].quantity>0 && e.models[0].id!=="d9e8ug").map((current:any, index:number) => {
            let promoModel = current.models.find((model: any) => model.promo && model.promo.percent !== 0);
            let promoPrice = (current.models[0].price + getUnitaryIVA(current.models[0]))*(cart[promoModel?.id]||1)
            let percent = Math.ceil(current.models[0].promo.percent)
            let price = (promoPrice * (1+(percent/100)))
            return(
              <>
                <div className={'PromotionScreen-Card'}>

                  <div
                    onClick={()=>{if(!isClicking){setSelectedProduct(current);showModal()}}}
                    id={current.models[0]}
                    className="PromotionScreen-productImg"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${current.models[0].imgId})`
                    }}
                  >
                    {current.models[0].promoPrice ? <div className={'PromotionScreen-Card-Flag'}><TagsFilled
                      className={'PromotionScreen-TagsFilled'}/>{Math.ceil(percent)}%</div> : <></>}
                  </div>

                  <div className={'PromotionScreen-textContainer'}>
                    <p className={'PromotionScreen-name'}>{current.name}</p>
                    <div className={'PromotionScreen-priceContainer'}>
                      {current.models[0].promoPrice  ?
                        <p
                          className={'PromotionScreen-price'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(price )}</p>:<></>}
                      {promoPrice ?
                        <p
                          className={'PromotionScreen-promoPrice'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(promoPrice )}</p>:<></>}
                    </div>
                  </div>
                  <div className={'PromotionScreen-purchaseCont'}><AMPMPurchaseProduct  product={current.models[0]} hideAvailableStock={false}/></div>
                </div>
              </>
            )
          })}
        </div>
      )}
    else {
      return(
        <div className={'PromotionScreen-carousel'}>
          {BSProducts.length?
            BSProducts.filter(e=> e.models[0].quantity>0 && e.models[0].id!=="d9e8ug").map((current:any, index:number) => {
              let promoModel = current.models.find((model: any) => model.promo && model.promo.percent !== 0);
              let promoPrice = (current.models[0].price + getUnitaryIVA(current.models[0]))*(cart[promoModel?.id]||1)
              let percent = Math.ceil(current.models[0].promo.percent)
              let price = (promoPrice * (1+(percent/100)))
            return(
              <>
                <div className={'PromotionScreen-Card-BS'} >

                  <div
                    onClick={()=>{setSelectedProduct(current);showModal()}}
                    id={current.models[0].imgId}
                    className="PromotionScreen-productImg"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${current.models[0].imgId})`
                    }}
                  >
                    {current.models[0].promoPrice  ? <div className={'PromotionScreen-Card-Flag'}><TagsFilled
                      className={'PromotionScreen-TagsFilled'}/>{Math.ceil(percent)}%</div> : <></>}
                  </div>

                  <div className={'PromotionScreen-textContainer'}>
                    <p className={'PromotionScreen-name'}>{current.name}</p>
                    <div className={'PromotionScreen-priceContainer'}>
                      {current.models[0].promoPrice  ?
                        <p
                          className={'PromotionScreen-price'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(price )}</p>:<></>}
                      {promoPrice ?
                        <p
                        className={'PromotionScreen-promoPrice'}>{state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(promoPrice )}</p>:<></>}
                    </div>
                  </div>
                  <div className={'PromotionScreen-purchaseCont'}><AMPMPurchaseProduct  product={current.models[0]} hideAvailableStock={false}/></div>
                </div>
              </>
            )
          })
          :<></>}
        </div>
      )
    }
  }

  const showModal = () => {
    if (show) {
      setShow(false)
    } else {
      setShow(true)
    }
  }


  return(
    <>{
      allPromo? <div className={'PromotionScreen-AllPromo-Container'}>
        <div className={'PromotionScreen-AllPromo-Grid'}>
          {filteredPromos.slice(allPromoPagination,allPromoPagination+20).map((current:any, index:number) => {
            return(
              <ProductItem
                key={index}
                editing
                merchant={props.merchant}
                item={current}
                products={props.promos}
              />)
          })}
        </div>
        {!allPromo?<button className={'PromotionScreen-button-next'} onClick={() => {
          props.setPromotionScreen(false);
        }}>Continuar</button>:<></>}
        <LeftOutlined className={'PromotionScreen-left'} onClick={()=>setAllPromo(false)}/>
        <div className={'PromotionScreen-paginator-cont'}>
          {allPromoPagination !== 0 &&
            <LeftOutlined onClick={() => setAllPromoPagination(allPromoPagination - 20)}
                          className={allPromoPagination !== 0 ? 'PromotionScreen-icon' : 'PromotionScreen-icon-d'}/>}
          {(allPromoPagination/20)+1}
          {allPromoPagination < filteredPromos.length - 20 &&
            <RightOutlined onClick={() => setAllPromoPagination(allPromoPagination + 20)}
                           className={allPromoPagination < filteredPromos.length - 20 ? 'PromotionScreen-icon' : 'PromotionScreen-icon-d'}/>}
        </div>
      </div>
        :
        <div className='PromotionScreen-Container'>
          <div className='PromotionScreen-welcome' style={{ backgroundImage: `url(${promotionInfo.image})` }}>
            <p className='PromotionScreen-welcome-text'>
              {promotionInfo.message}
            </p>
          </div>
          <div className='PromotionScreen-header'>
            <p className='PromotionScreen-Title'>Promociones</p>
            <p className='PromotionScreen-seeAll' onClick={()=>setAllPromo(true)}>Ver todo</p>

          </div>
          {!props.loadingPromo?
            <ScrollHandlerWrapper children={PromoCarouselContent} viewChange={true}/>:
            <div className={'PromotionSpinner'}><div className={'loader-fb-promotion'}/></div>
          }


          {isMobile? <ImagesTemp contentRef={null} isClicking={false}/> : <ScrollHandlerWrapper children={ImagesTemp} viewChange={true}/>}
          {

            BSProducts.length?<div className="PromotionScreen-header">
              <p className="PromotionScreen-Title">Vuelve a comprar</p>
            </div>: <></>}
          {!props.loadingPromo?<ScrollHandlerWrapper children={BSProductContent} viewChange={true}/>:<div className={'PromotionSpinner'}><div className={'loader-fb-promotion'}/></div>}



          <button className={'PromotionScreen-button-next'} onClick={()=> {
            props.setPromotionScreen(false);
          }}>Continuar</button>
        </div>

    }</>

  )
}

