import {Tooltip} from 'antd';
import {useIntl} from 'react-intl';
import {Context} from '../../Store';
import {ProductModal} from './ProductModal';
import {TagsFilled} from '@ant-design/icons';
import * as HttpStatus from 'http-status-codes';
import {Utils} from '../../../services/Utils.class';
import {PurchaseProduct} from '../actions/PurchaseProduct';
import React, {useContext, useState, useEffect} from 'react';
import {ProductInterface} from '../../product/ProductInterface';
import {HttpRequest} from '../../../services/HttpRequest.class';

export function ProductCard (props: {
  item: any,
  details?:any,
  prototype: any,
  properties: any,
  product: ProductInterface
}) {
  const intl = useIntl();
  const merchant = Utils.getMerchantByUrl();
  const [state, dispatch] = useContext(Context);
  const [show, setShow] = useState(false);
  const [newPrice, setNewPrice] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hideProductCode, setHideProductCode] = useState(false);
  const [hideAvailableStock, setHideAvailableStock] = useState(false);
  const [hideProductDescription, setHideProductDescription] = useState(false);

  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('product');

  useEffect(() => {
    getRules();
    if (id === props.product.id) {
      setShow(true);
    }
  }, []);

  const putProductPrice = async (product: any) => {
    setLoading(true);
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const productRequest = await request.put(`product/${product.id}`, product);
    if (productRequest.status !== HttpStatus.CREATED) {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_STOCK_PRODUCT_CARD__ERROR_NOTIFICATION_TITLE_UPDATING_PRODUCT'}),
        intl.formatMessage({id: 'CASHIER_STOCK_PRODUCT_CARD__ERROR_NOTIFICATION_MESSAGE_UPDATING_PRODUCT'})
      );
    }

    if (productRequest.status === HttpStatus.CREATED) {
      const position = getProductPositionState(product.id);
      if (Object.keys(position).length) {
        dispatch({
          type: 'UPDATE-PRODUCT',
          payload: {
            ...position,
            product: product
          }
        });
      }
    }

    setLoading(false);
  };

  const getRules = () => {
    if (state.ruleParams.hideProductCode) setHideProductCode(true);
    if (state.ruleParams.hideAvailableStock) setHideAvailableStock(true);
    if (state.ruleParams.hideProductDescription) setHideProductDescription(true);
  };

  const getProductById = (id: string) => {
    for (const product of state.products[state.merchantId]) {
      for (const currentProduct of product.models) {
        if (currentProduct.id == id) {
          return currentProduct;
        }
      }
    }

    return {
      details: []
    };
  };

  let img = (
    <i
      id={`Cashier-item-img-${props.product.name}`}
      className={'large material-icons Customer-merchant-icon'}
    >loyalty</i>
  );

  const getProductPositionState = (id: string) => {
    let productPosition = {};
    state.products[state.merchantId]?.map((product: any, index: number) => {
      product.models?.map((currentProduct: any, key: number) => {
        if (currentProduct.id == id) {
          productPosition = {
            key: key,
            index: index
          };
        }
      });
    });

    return productPosition;
  };

  const getDiscount = (price: number, discount: any) => {
    let discountToDiv = discount * 100;
    return Math.floor(100 - discountToDiv / price);
  };

  const getTotalPriceProduct = (product: any) => {
    let total = product.price * (cart[product.id] ? cart[product.id] : 1);
    if (product.promo && Object.keys(product.promo).length) {
      const {type, value, percent} = product.promo;
      if (type === 'regular' && !product.promoPrice && cart[product.id] >= 1) {
        let productPriceDiscount = ((product.price / 100) * percent);
        total = (product.price - productPriceDiscount) * cart[product.id];
      }
      if (type === 'quantity' && !product.promoPrice && cart[product.id] >= Number(value)) total -= (product.price / 100) * percent;
    }
    return total;
  };

  if (props.product.imgId !== '') {
    img = (
      <img
        alt={'logo'}
        id={`${props.product.imgId}`}
        className={'Cashier-item-productImg'}
        src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${props.product.imgId}`}
      />
    );
  }

  let price = (
    <p id={`Cashier-item-price-${props.product.id}`} className={props.product.promoPrice && props.product.promoPrice > 0 ? 'Cashier-item-price' : 'Cashier-item-price-withOutPromo'}>
      {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(getTotalPriceProduct(props.product), state.ruleParams.urlStockEnable ? 0 : 2)}
    </p>
  );

  let promoPrice = (
    props.product.promoPrice && props.product.promoPrice > 0 ? (
        <p id={`Cashier-item-price-${props.product.id}`} className={'Cashier-item-price-Promo'}>
          {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney((cart[props.product.id] ? cart[props.product.id] : 1) * props.product.promoPrice, state.ruleParams.urlStockEnable ? 0 : 2)}
        </p>
      )
      : <p/>
  );

  if (props.product.id === state.ruleParams.idVariableProduct) {
    price = (
      <div className={'Cashier-item-variable-price'}>
        {state.currentMerchant.isoCurrency.symbol}
        <input type={'number'} onChange={(event: any) => setNewPrice(event.target.value)}/>
        <i className={`medium material-icons ready cursor`} onClick={() => {
          if (newPrice) {
            let currentStateProduct = getProductById(props.product.id);
            currentStateProduct.price = parseFloat(String(newPrice));
            putProductPrice(currentStateProduct).then();
          }
        }}>{loading ? 'loop' : 'check'}</i>
      </div>
    );
  }

  let mark = '';
  let measure = '';
  props.product.details?.map((currentDetail: any) => {
    if (currentDetail.type === 'medida') measure = currentDetail.value;

    if (currentDetail.type === 'marca') mark = currentDetail.value;
  });

  const showModal = () => {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  };

  return (
    <>
      <div>
        {show ? <ProductModal hideQuantity={hideAvailableStock} priceTotal={getTotalPriceProduct(props.product)} item={props.item} key={props.product.id} visible={show} setShowModal={setShow} product={props.product}
                              products={state.products[merchant]}/> : ''}
      </div>
      <div className={'Cashier-item-img'} onClick={showModal}>
        {img}
      </div>

      {merchant != 'pumacolombia' ?
        <span style={{backgroundColor: `${state.currentMerchant.styles.color2}`}} className={cart[props.product.id] ? 'Cashier-item-img-quantity Cashier-item-img__full' : 'Cashier-item-img-quantity'}>
                {cart[props.product.id] ? cart[props.product.id] : 0}
        </span> :
        <></>
      }

      <span id={`Cashier-item-name-${props.product.id}`} className={'Cashier-item-name'}>{props.product.name}</span>

      {
        props.item.models[0].details.filter((e: any) => e.type === 'singleSelection').length || props.item.models[0].details.filter((e: any) => e.type === 'buyTogether').length ?
          <span id={`Cashier-item-combo-${props.product.id}`} className={'Cashier-item-combo'}>Combo</span> :
          <></>
      }

      {(props.product.promo && Object.keys(props.product.promo).length > 0) || props.product.promoPrice && props.product.promoPrice > 0 ?
        <div className={'Icon-Outlined-Gift-card'}>
          <Tooltip title={props.product.promo.description}>
            <TagsFilled style={{fontSize: '28px'}}/>
          </Tooltip>
          {state.ruleParams?.promotionIconText &&
            <span
              className={'Icon-Promotion-Text'}>{props.product.promoPrice && props.product.promoPrice > 0 && !Object.keys(props.product.promo).length ? `${getDiscount(props.product.promoPrice, props.product.price)}%` : `${Math.floor(props.product.promo.percent)}%`}</span>
          }
        </div> : <></>
      }

      {!hideProductCode
        ? <span id={`Cashier-item-code-${props.product.id}`}
                className={'Cashier-item-description'}>{`${intl.formatMessage({id: 'CASHIER_STOCK_PRODUCT_CARD__LABEL_PRODUCT_CODE'})}: ${props.product.code}`}</span>
        : <></>
      }

      {!hideProductDescription ?
        props.product.description &&
        <div className={'cashier-item-description'}>
          <span
            id={`Cashier-item-description-${props.product.id}`}
            style={{color: `${state.currentMerchant.styles?.color1}`}}
            className={'Cashier-item-description secondaryTXT'}
          >
              {props.product.description} {measure} {mark}
          </span>
        </div> : <></>
      }

      <div className={'Cashier-item-controls-content'}>
        {promoPrice}
        {price}
        {props.details ? props.details : <></>}
        <span className={'Cashier-item-controls'}>
          {
            props.product.details.filter((e: any) => e.type === 'singleSelection').length && !cart[props.product.id] ?
              <div style={{backgroundColor: `${state.currentMerchant.styles.color2}`}}
                   className={`PurchaseProduct ${(!props.product.quantity || props.product.price == 0) && 'PurchaseProduct__unavailable'}`}
                   onClick={showModal}>
                <span className={'Cashier-item-withOutStock'}>{intl.formatMessage({id: 'CASHIER_ACTIONS__BUTTON_BUY'})}</span>
              </div>
              :
              <PurchaseProduct product={props.product} hideAvailableStock={hideAvailableStock} merchant={merchant}/>
          }
        </span>
      </div>
    </>
  );
}