import {Context} from '../../../Store';
import {Link} from 'react-router-dom';
import React, {useContext} from 'react';
import {useRouteMatch} from 'react-router';
import './NewCategoryCard.css'

export function NewCategoryCard(props:{
  category?:string,
  setSearchContent:Function,
  editing?:boolean,
  lastCategory:string,
  productCategory:any,
  setProducts:Function,
  setCategory?:Function,
  setLoadProducts:Function,
  setLastCategory?:Function,
  setLoadingContent:Function,
  setLoadCategories:Function,
  setVirtualPath: Function
}) {
  const {url} = useRouteMatch();
  const [state] = useContext(Context);
  const path = props.productCategory.hasOwnProperty('parent') && props.productCategory.parent && props.productCategory.type === 2 ?
     `${props.productCategory.parent}/${props.productCategory.name}` :
    `${props.productCategory.name}`;

  const loadProducts = () => {
    props.setLoadingContent(true);
    if (props.productCategory.hasOwnProperty('parent') && props.productCategory.parent && props.productCategory.type === 2 ||
      props.productCategory.hasOwnProperty('children') && !props.productCategory.children.length && props.productCategory.type === 0) {
      props.setLoadProducts(true);
    } else {
      props.setProducts([]);
      props.setLoadCategories(true);
    }
    props.setLoadingContent(false);
  };

  let img = (
    <i
      id={`category-item-img-${props.productCategory.name}`}
      className={'large material-icons Cashier-category-item-productIcon'}
    >loyalty</i>
  );

  if (props.productCategory.imgId !== '') {
    img = (
      <img
        alt={'logo'}
        id={`${props.productCategory.imgId}`}
        className={'category-item-productImg'}
        src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${props.productCategory.imgId}`}
      />
    );
  }

  return (
    <div className={'category-item Cashier-item'}

         id={`Cashier-item-${props.productCategory.name}`}
         key={'Cashier-item-' + props.productCategory.name}>
      {
        props.editing?
          <div
            className={'category-item-content'}
            onClick={()=>{
              props.setVirtualPath(path);
              if(!props.category) {
                props.setCategory && props.setCategory(props.productCategory.name)
                props.setLastCategory && props.setLastCategory(props.productCategory.name)
              } else {
                props.setLastCategory && props.setLastCategory(props.productCategory.name)
              }

            }}

            >
            <div style={{color: `${state.currentMerchant.styles.color1}`}} className={'category-name'}
                 >
                          <span
                            style={{color:'#000'}}
                            id={`category-item-name-${props.productCategory.name}`}
                            className={'category-item-name'}
                          >{props.productCategory.description}</span>
            </div>
            <div className={'category-img'} >
              {img}
            </div>

          </div>
          :
          <Link
            to={path}
            onClick={loadProducts}
          >
            <div className={'Cashier-category-img'}>
              {img}
            </div>
            <div style={{color: `${state.currentMerchant.styles.color1}`}} className={'category-name'}>
                      <span
                        id={`Cashier-item-name-${props.productCategory.name}`}
                      >{props.productCategory.description}</span>
            </div>
          </Link>
      }
    </div>
  )
}