import './AddAndRemoveProduct.css';
import {Context} from '../../Store';
import React, {useContext} from 'react';
import {Button, Icon} from 'react-materialize';
import {ProductInterface} from '../../product/ProductInterface';

export function AddAndRemoveProduct (props:{
  message:string,
  product:ProductInterface
}) {
  const [state, dispatch] = useContext(Context);
  const cart = state.merchantParams?.noClient ? (state.cart[state.merchantParams.noClient] ? state.cart[state.merchantParams.noClient] : state.cart[0]) : state.cart[0];

  const getProductById = (id:string) => {
    for (const product of state.products[state.merchantId]) {
      if(product.models) {
        for (const currentProduct of product.models) {
          if (currentProduct.id == id) {
            return currentProduct;
          }
        }
      }
      else {
        if (product.id == id) {
          return product;
        }
      }
    }
  }


  const addProductToCart = (id:string) => {
    const product = getProductById(id);
    if (Object.keys(product).length) {
      const quantity = [product.id] ? cart[product.id] + 1 : 1;

      dispatch({
        type: 'ADD-CARD',
        payload: {
          [id]: quantity
        }
      });
    }
  };

  const removeProductToCart = (id:string) => {
    const product = getProductById(id);
    if (Object.keys(product).length) {
      const quantity = cart[product.id] - 1;
      dispatch({
        type: 'ADD-CARD',
        payload: {
          [id]: quantity
        }
      });
    }
  };

  return (
    <div id={`Cashier-item-adding-${props.product.id}`} className={'Cashier-item-adding'}>
      <Button
        floating
        waves={'light'}
        className={'Cashier-item-adding-btn'}
        disabled={!cart[props.product.id]}
        onClick={() => removeProductToCart(props.product.id)}
        icon={<Icon style={{color:`${state.currentMerchant.styles.color1}`}} className={'Cashier-item-remove-btn'}>remove</Icon>}
      />

      <p  style={state.currentMerchant?.styles?{fontFamily: state.currentMerchant?.styles?.font2}:{}} id={`Cashier-item-quantity-${props.product.id}`} className={'Cashier-item-quantity'}>
        {props.message}
      </p>

      <Button
        floating
        waves={'light'}
        className={'Cashier-item-adding-btn'}
        onClick={() => addProductToCart(props.product.id)}
        icon={<Icon style={{color:`${state.currentMerchant.styles.color1}`}}>add</Icon>}
        disabled={cart[props.product.id] == props.product.quantity || props.product.id == state.ruleParams.idProductShipping}
      />
    </div>
  )
}
