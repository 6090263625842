import React from "react";
import {Skeleton} from 'antd';
import "./SkeletonToroso.css";

export function SkeletonToroso() {
  return (
    <div className={'SkeletonToroso'}>
      <Skeleton.Input size={"small"} active={true} className={'skeletonPrice'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInput'}/>
    </div>
  )
}