import {MOOVIN} from './moovin/MOOVIN';
import {Shipment} from './zubale/Shipment';
import {ShipmentCR} from './shipmentCr/ShipmentCR';
import {ShipmentDefault} from './default/ShipmentDefault';
import {ShipmentCO} from "./shipmentCO/ShipmentCO";

const shipments:any = {
    'Moovin': MOOVIN,
    'Zubale': Shipment,
    'Vtex': ShipmentCR,
    'Moddo': ShipmentCO,
    'CorreosCR': ShipmentCR,
    'Default': ShipmentDefault
}

export default shipments;
