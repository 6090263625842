import React from "react";
import {Skeleton} from 'antd';
import "./SkeletonMiscellaneuos.css";

export function SkeletonMiscellaneuos() {
  return (
    <div className={'SkeletonMiscellaneuos'}>
      <Skeleton.Input size={"small"} active={true} className={'skeletonPrice'}/>
      <div className={'skeletonForm'}>
        <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputDescription'}/>
        <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputResume'}/>
        <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputOrders'}/>
        <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputTotal'}/>
        <Skeleton.Button size={"large"} active={true} className={'skeletonInputs skeletonButtonConfirm'}/>
        <Skeleton.Button size={"large"} active={true} className={'skeletonInputs skeletonButtons'}/>
        <Skeleton.Button size={"large"} active={true} className={'skeletonInputs skeletonButtons'}/>
        <Skeleton.Button size={"large"} active={true} className={'skeletonInputs skeletonButtons'}/>
      </div>
    </div>
  )
}