import {useParams} from 'react-router';
import { Card, Col, Row, Radio } from 'antd';
import * as HttpStatus from 'http-status-codes';
import {Context} from '../../../components/Store';
import {Utils} from '../../../services/Utils.class';
import React, {useContext, useEffect, useState} from 'react';
import {HttpRequest} from '../../../services/HttpRequest.class';

export function MOOVIN(props: {
    addresses: {
        lat: number, long: number,
        setLat: Function, setLong: Function
    },
    setOptionService: Function
}) {
    const {orderId} = useParams();
    const [state] = useContext(Context);
    const merchant = Utils.getMerchantByUrl();
    const [services, setServices] = useState(<></>);

    const onChange = (typeService: any) => {
        props.setOptionService(typeService.target.value);
    };

    useEffect(() => {
        if (state.merchantId) {
            getEstimation().then();
        }
    }, []);

    const getEstimation = async () => {
        const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
        const estimation = await request.post({
            orderId: orderId,
            addresses: {
                pointCollect: {latitude: state.currentMerchant.location.lat, longitude: state.currentMerchant.location.long},
                pointDelivery: {latitude: props.addresses.lat, longitude: props.addresses.long}
            },
            customerId: state.username,
            estimation: {}
        }, 'shipment/moovin');

        if (estimation.status === HttpStatus.CREATED) {
            const optionService = estimation.data.optionService;

            let colCard = (<></>);
            for (const current of optionService) {
                let serviceType = (current.type === 'route') ? 'Ruta' : 'Bajo demanda';
                colCard =
                    <>
                        {colCard}
                            <Col span={12}>
                                <Card title={<Radio value={current.id}>{serviceType}</Radio>} bordered={false}>
                                    <div className={'Text-costo'}>Tiempo de llegada: {current.deliveryTime}h</div>
                                    <div className={'Text-costo'}>Monto de envío: {current.amount}</div>
                                </Card>
                            </Col>
                    </>;
            }

            let cardOption =
                <Radio.Group defaultValue={1} onChange={onChange}>
                    <div className="site-card-wrapper">
                        <Row gutter={24}>
                            {colCard}
                        </Row>
                    </div>
                </Radio.Group>;

            setServices(cardOption);
        } else {
            Utils.showNotificationMessage('error', 'Agregando estimación', 'Se presentó un error agregando la estimación en el envío del pedido.');
        }
    };

    return (<>
        <div className={'Menu-pago'}>
            {services}
        </div>
    </>);
}
