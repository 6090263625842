import {Input, Form} from 'antd';
import '../shipmentCr/ShipmentCR';
import PhoneInput from 'react-phone-input-2';
import {Map} from '../../../components/map/Map';
import * as HttpStatus from 'http-status-codes';
import {Context} from '../../../components/Store';
import {FormattedMessage, useIntl} from 'react-intl';
import {HttpRequest} from '../../../services/HttpRequest.class';
import React, {useContext, useEffect, useRef, useState} from 'react';

export function ShipmentDefault(props: {
    values: any,
    errors: any,
    reset: boolean,
    markerIcon: any,
    handleSubmit: any,
    setValues: Function,
    setErrors: Function,
    stepEnabled: boolean,
    handleChange: Function,
    setStepEnabled: Function
}) {
    const intl = useIntl();
    const searchInput = useRef(null);
    const [zoom, setZoom] = useState(11);
    const [stateContext, dispatch] = useContext(Context);
    const [address, setAddress] = useState('');
    const [isInRange, setIsInRange] = useState(false);

    useEffect(() => {
        if (!Object.keys(props.errors).length) validateEnabledStep();
    }, [props.errors]);


    useEffect(() => {
        if (stateContext.merchantParams?.encryptId) {
            getDecipherInfo().then();
        }
    }, [])

    useEffect(() => {
       props.values.address = address;
    }, [address])

    useEffect(() => {
        if (props.reset && !props.stepEnabled) {
            props.setErrors({});
            props.setValues({});
        }
    }, [props.reset]);

    const getDecipherInfo = async () => {
        const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
        const decipherDataRequest: any = await request.get(`encrypt/${stateContext.merchantParams.encryptId}`);

        if (decipherDataRequest.status === HttpStatus.OK) {
            const decipherData = JSON.parse(decipherDataRequest.data.decipherData);

            if (decipherData.encryptId) {
                setMerchantParams({
                    encryptId: decipherData.encryptId
                });
            }
        }
    };

    const setMerchantParams = (params: object) => {
        dispatch({
            type: 'ADD-MERCHANT-PARAMS',
            payload: params
        });
    }

    if (stateContext?.merchantParams?.encryptId) {
        props.values.email = stateContext?.merchantParams.email;
        props.values.idType = stateContext?.merchantParams.idType;
        props.values.DNI = stateContext?.merchantParams.identification;
        props.values.firstName = stateContext?.merchantParams.name.split(' ')[0];
        props.values.lastName = stateContext?.merchantParams.name.split(' ')[1];
    }

    const validateEnabledStep = () => {
        const enabled = !Object.keys(props.errors).length;
        if (!props.stepEnabled && enabled && Object.keys(props.values).length && isInRange) props.setStepEnabled(true);
        if (props.stepEnabled && (!enabled || !Object.keys(props.values).length)) props.setStepEnabled(false);
    }

    return (
        <form className={'Form pickup-form'}>
            {isInRange ? (props.values.isInRange = true) : !props.values.isInRange && props.errors.isInRange && (
                <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.isInRange}/>}</span>
            )}

            <div className={'map'}>
                <input
                    type={'text'}
                    value={address}
                    ref={searchInput}
                    style={{color:`${stateContext.currentMerchant.styles.color1}`}}
                    className={'shipment-form-input'}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_SEARCH'})}
                />
                <Map zoom={zoom}
                     search={searchInput}
                     getPolygons={()=>{}}
                     setAddress={setAddress}
                     setUserInRange={setIsInRange}
                     handleChange={props.handleChange}
                     state={stateContext.currentMerchant}
                     valueLocation={props.values.location}
                />
            </div>


            <Form.Item>
                <Input
                    maxLength={30}
                    name={'firstName'}
                    value={props.values.firstName}
                    onChange={(event: any) => props.handleChange(event)}
                    placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_FIRSTNAME'})}
                    style={{color:`${stateContext.currentMerchant.styles.color1}`}}
                    className={`shipment-form-input-margin ${props.errors.firstName && 'shipment-form-input__error'}`}
                />

                {props.errors.firstName && (
                    <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.firstName}/>}</span>
                )}
            </Form.Item>

            <Form.Item>
                <Input
                    maxLength={30}
                    name={'lastName'}
                    value={props.values.lastName}
                    onChange={(event: any) => props.handleChange(event)}
                    placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_LASTNAME'})}
                    style={{color:`${stateContext.currentMerchant.styles.color1}`}}
                    className={`shipment-form-input ${props.errors.lastName && 'shipment-form-input__error'}`}
                />

                {props.errors.lastName && (
                    <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.lastName}/>}</span>
                )}
            </Form.Item>

            <Form.Item>
                <Input
                    name={'DNI'}
                    maxLength={11}
                    value={props.values.DNI}
                    onChange={(event: any) => props.handleChange(event)}
                    placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_DNI'})}
                    style={{color:`${stateContext.currentMerchant.styles.color1}`}}
                    className={`shipment-form-input ${props.errors.DNI && 'shipment-form-input__error'}`}
                />

                {props.errors.DNI && (
                    <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.DNI}/>}</span>
                )}
            </Form.Item>

            <Form.Item>
                <div
                    style={{color:`${stateContext.currentMerchant.styles.color1}`}} className={`shipment-form-input-phone ${props.errors.phoneNumber && 'shipment-form-input__error'}`}>
                    <PhoneInput
                        country={'co'}
                        placeholder={'+57 8588-0000'}
                        inputClass={`Form-phone-input`}
                        value={props.values.phoneNumber}
                        inputProps={{name: 'phoneNumber'}}
                        onChange={(values: any, country: any, event: any) => {
                            event.persist();
                            if (event.target) {
                                props.values['country'] = country;
                                props.handleChange(event);
                            }
                        }}
                    />

                    {props.errors.phoneNumber && (
                        <span className={'input-error-phone Form-input-error'}>{<FormattedMessage
                            id={props.errors.phoneNumber}/>}</span>
                    )}
                </div>
            </Form.Item>

            <Form.Item>
                <Input
                    name={'email'}
                    maxLength={30}
                    value={props.values.email}
                    onChange={(event: any) => props.handleChange(event)}
                    placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_EMAIL'})}
                    style={{color:`${stateContext.currentMerchant.styles.color1}`}}
                    className={`shipment-form-input ${props.errors.email && 'shipment-form-input__error'}`}
                />

                {props.errors.email && (
                    <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.email}/>}</span>
                )}
            </Form.Item>

        </form>
    );
}