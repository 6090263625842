import {lazy} from 'react';

const FAC = lazy(() => import('./fac/FAC').then(({ FAC }) => ({ default: FAC })));
const CGP = lazy(() => import('./cgp/CGP').then(({ CGP }) => ({ default: CGP })));
const PayU = lazy(() => import('./payu/PayU').then(({ PayU }) => ({ default: PayU })));
const Toroso = lazy(() => import('./toroso/Toroso').then(({ Toroso }) => ({ default: Toroso })));
const Niubiz = lazy(() => import('./niubiz/Niubiz').then(({ Niubiz }) => ({ default: Niubiz })));
const PayUPSE = lazy(() => import('./payu/PayUPSE').then(({ PayUPSE }) => ({ default: PayUPSE })));
const Greenpay = lazy(() => import('./greenpay/Greenpay').then(({ Greenpay }) => ({ default: Greenpay })));
const Cardinal = lazy(() => import('./cardinal/Cardinal').then(({ Cardinal }) => ({ default: Cardinal })));
const BancoNacional = lazy(() => import('./bancoNacional/BancoNacional').then(({ BancoNacional }) => ({ default: BancoNacional })));
const Miscellaneous = lazy(() => import('./miscellaneous/Miscellaneous').then(({ Miscellaneous }) => ({ default: Miscellaneous })));

const processors:any = {
  'FAC': FAC,
  'CGP': CGP,
  'PayU': PayU,
  'FACT03': FAC,
  'FACT06': FAC,
  'Toroso': Toroso,
  'Niubiz': Niubiz,
  'PowerTranz': FAC,
  'PayUPSE': PayUPSE,
  'Greenpay': Greenpay,
  'Cardinal': Cardinal,
  'PowerTranzAsync': FAC,
  'Miscellaneous': Miscellaneous,
  'BancoNacional': BancoNacional
}

export default processors;
