import {ShipmentCRValidators} from './shipmentCr/ShipmentCRValidators';
import {ShipmentZubaleValidators} from './zubale/ShipmentZubaleValidators';
import {ShipmentDefaultValidators} from './default/ShipmentDefaultValidators';
import {ShipmentCOValidators} from "./shipmentCO/ShipmentCOValidators";


const shipmentsValidators:any = {
  'Vtex': ShipmentCRValidators,
  'CorreosCR': ShipmentCRValidators,
  'Moddo': ShipmentCOValidators,
  'Zubale': ShipmentZubaleValidators,
  'Default': ShipmentDefaultValidators
}

export default shipmentsValidators;