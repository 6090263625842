import './BillingInformation.css';
import {useIntl} from 'react-intl';
import {Context} from '../../Store';
import {TextInput} from 'react-materialize';
import * as HttpStatus from 'http-status-codes';
import React, {useContext, useEffect} from 'react';
import {Utils} from '../../../services/Utils.class';
import {HttpRequest} from '../../../services/HttpRequest.class';

export function BillingInformation(props: {
    values:any,
    errors:any,
    handleSubmit:any,
    merchantParams?:any,
    handleChange:Function
}) {
    const intl = useIntl();
    const merchant = Utils.getMerchantByUrl();
    const [state, dispatch] = useContext(Context);

    useEffect(() => {
        dispatch({
            payload: merchant,
            type: 'ID-MERCHANT'
        });
        merchantInfo().then();
    }, []);

    useEffect(()=>{
      if (props.merchantParams?.identification) {
        props.values.name = props.merchantParams?.name;
        props.values.email = props.merchantParams?.email;
        props.values.DNI = props.merchantParams?.identification;
      }
    },[])

    const merchantInfo = async () => {
        const request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
        const merchantRequest = await request.get(`merchant/${merchant}`);
        if (merchantRequest.status === HttpStatus.OK) {
            merchantRequest.data.links = Object.assign(state.currentMerchant.links, merchantRequest.data.links || {});

            dispatch({
                type: 'CURRENT-MERCHANT',
                payload: merchantRequest.data
            });

            props.handleChange({
                target: {
                    name: 'country',
                    value: merchantRequest.data.country.countryCode.toUpperCase()
                }
            });
        } else if (merchantRequest.status === HttpStatus.NOT_FOUND) {
            Utils.showNotificationMessage(
                'info',
                intl.formatMessage({id:'CASHIER_BILLING__INFO_NOTIFICATION_TITLE_BUSINESS_NOT_FOUND'}),
                intl.formatMessage({id:'CASHIER_BILLING__INFO_NOTIFICATION_MESSAGE_BUSINESS_NOT_FOUND'}),
            );
        } else {
            Utils.showNotificationMessage(
                'error',
                intl.formatMessage({id:'CASHIER_BILLING__ERROR_NOTIFICATION_TITLE_FETCHING_BUSINESS'}),
                intl.formatMessage({id:'CASHIER_BILLING__ERROR_NOTIFICATION_MESSAGE_FETCHING_BUSINESS'}),
            );
        }
    }

    return (
        <form className={'Form-billing'} style={{marginLeft:0}} onSubmit={props.handleSubmit}>
            <p className="billingInformation-title center">{intl.formatMessage({id:'BILLING__TITLE'})}</p>
            <TextInput
                name={'name'}
                value={props.values.name}
                onInput={(event: Event) => {
                    props.handleSubmit();
                    props.handleChange(event);
                }}
                label={intl.formatMessage({id:'CASHIER_BILLING__TEXT_INPUT_LABEL_NAME'})}
                inputClassName={`Form-input ${!props.values.name && props.errors.name && 'Form-input-error__border'}`}
            />

            {!props.values.name && props.errors.name && (
                <span className={'Form-input-error'}>{props.errors.name}</span>
            )}

            <TextInput
                name={'email'}
                value={props.values.email}
                onInput={(event: Event) => {
                    props.handleSubmit();
                    props.handleChange(event);
                }}
                label={intl.formatMessage({id:'CASHIER_BILLING__TEXT_INPUT_LABEL_EMAIL'})}
                inputClassName={`Form-input ${!props.values.email && props.errors.email && 'Form-input-error__border'}`}
            />

            {props.errors.email && props.errors.email !== props.values.email && (
                <span className={'Form-input-error'}>{props.errors.email}</span>
            )}

            <TextInput
              type={'number'}
                name={'DNI'}
                value={props.values.DNI}
                onInput={(event: Event) => {
                    props.handleSubmit();
                    props.handleChange(event);
                }}
                label={intl.formatMessage({id:'CASHIER_BILLING__TEXT_INPUT_LABEL_DNI'})}
                inputClassName={`Form-input ${!props.values.DNI && props.errors.DNI && 'Form-input-error__border'}`}
            />

            {!props.values.DNI && props.errors.DNI && (
                <span className={'Form-input-error'}>{props.errors.DNI}</span>
            )}
        </form>
    );
}
