const ActionTypes = {
  USERNAME: 'USERNAME',
  ADD_CARD: 'ADD-CARD',
  BACKGROUND: 'BACKGROUND',
  CLEAN_CART: 'CLEAN-CART',
  ID_MERCHANT: 'ID-MERCHANT',
  UPDATE_CART: 'UPDATE-CART',
  ADD_PRODUCTS: 'ADD-PRODUCTS',
  ADD_MERCHANTS: 'ADD-MERCHANTS',
  UPDATE_PRODUCT: 'UPDATE-PRODUCT',
  CURRENT_POINTS: 'CURRENT-POINTS',
  ADD_CATEGORIES: 'ADD-CATEGORIES',
  ADD_RULE_PARAMS: 'ADD-RULE-PARAMS',
  CURRENT_MERCHANT: 'CURRENT-MERCHANT',
  CURRENT_FAVORITES:  'CURRENT-FAVORITES',
  ADD_MERCHANT_PARAMS: 'ADD-MERCHANT-PARAMS'
};

export const Reducer = (state:any, action:any) => {
  const currentCustomer = state.merchantParams?.noClient ? state.merchantParams.noClient : 0;
  state.cart[currentCustomer] = state.cart[currentCustomer] ? state.cart[currentCustomer] : {total: 0};

  switch (action.type) {
    case ActionTypes.USERNAME:
      return persist({ ...state, username: action.payload });

    case ActionTypes.BACKGROUND:
      return persist({ ...state, background: action.payload });

    case 'ADD-MERCHANTS':
      return persist({
        ...state,
        merchants: [...action.payload]
      });
    case 'ID-MERCHANT':
      return persist({
        ...state,
        merchantId: action.payload
      });
    case 'CURRENT-MERCHANT':
      return persist({
        ...state,
        currentMerchant: action.payload
      });
    case 'ADD-PRODUCTS':
      return persist({
        ...state,
        products: Object.assign(state.products, {[state.merchantId]: action.payload})
      });
    case 'ADD-CATEGORIES':
      return persist({
        ...state,
        categories: Object.assign(state.categories ? state.categories : {}, {[state.merchantId]: action.payload})
      });
    case 'ADD-CARD':
      let total = 0;
      state.cart[currentCustomer] = Object.assign(state.cart[currentCustomer], action.payload);
      for (const current in state.cart[currentCustomer]) {
        if (current !== 'total') {
          if (state.cart[currentCustomer].hasOwnProperty(current)) {
            total += state.cart[currentCustomer][current]
          }
        }
      }

      state.cart[currentCustomer].total = total;

      return persist({
        ...state,
        cart: state.cart
      });
    case 'UPDATE-CART':
      state.cart[currentCustomer] = Object.assign(state.cart[currentCustomer], action.payload);

      return persist({
        ...state,
        cart: state.cart
      });
    case 'CLEAN-CART':
      state.cart[currentCustomer] = {total: 0};

      return persist({
        ...state,
        cart: state.cart
      });
    case 'ADD-MERCHANT-PARAMS':
      return persist({
        ...state,
        merchantParams: action.payload
      });
    case 'ADD-RULE-PARAMS':
      return persist({
        ...state,
        ruleParams: action.payload
      });
    case 'UPDATE-PRODUCT':
      state.products[state.merchantId][action.payload?.index]['models'][action.payload?.key] = action.payload?.product;
      return persist({
        ...state,
        products: state.products
      });
    case 'CURRENT-FAVORITES':
      return persist({
        ...state,
        favorites: action.payload
      });
    case 'CURRENT-POINTS':
      return persist({
        ...state,
        showLoyaltyPoints: action.payload
      });

    default:
      return state;
  }
};

const persist = (payload:any) => {
  try {
    localStorage.setItem('store', JSON.stringify(payload));
    return payload;
  } catch (error) {
    // Handle error, such as quota exceeded error
    console.error('Error persisting state:', error);
    return payload;
  }
};