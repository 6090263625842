import {Input, Form} from 'antd';
import '../shipmentCr/ShipmentCR';
import PhoneInput from 'react-phone-input-2';
import {Map} from '../../../components/map/Map';
import * as HttpStatus from 'http-status-codes';
import {Context} from '../../../components/Store';
import {FormattedMessage, useIntl} from 'react-intl';
import {HttpRequest} from '../../../services/HttpRequest.class';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {Address} from '../../../components/shipment/address/Address';
import '../../../components/cashier/delivery/showAddress/ShowAddress.css'
import {AMPMMap} from '../../../components/map/AMPMMap';

export function Shipment(props: {
  values:any,
  setSelectValue?: Function,
  errors:any,
  reset:boolean,
  markerIcon:any,
  handleSubmit:any,
  setValues:Function,
  setErrors:Function,
  setStoreId:Function,
  stepEnabled:boolean,
  addAddress?: boolean,
  handleChange:Function,
  setStepEnabled:Function,
  setNewAddress?: Function
}) {
  const intl = useIntl();
  let infoWareHouse:any = [];
  const searchInput = useRef(null);
  const [zoom, setZoom] = useState(11);
  const [stateContext, dispatch] = useContext(Context);
  const [address, setAddress] = useState('');
  const [isInRange, setIsInRange] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [merchantPolygons, setMerchantPolygons] = useState([]);
  const [showShipmentAddress, setShowShipmentAddress] = useState(true);

  useEffect(()=>{
    getCoordsByGetConfig()
  },[])

  useEffect(()=>{
    props.addAddress&&setShowShipmentAddress(false)
  },[props.addAddress])
  
  useEffect(() => {
    if (!Object.keys(props.errors).length)  validateEnabledStep();
  }, [props.errors]);
  
  useEffect(() => {
    if (props.reset && !props.stepEnabled) {
      props.setErrors({});
      props.setValues({});
    }
  }, [props.reset]);

  if (stateContext?.merchantParams?.encryptId) {
    props.values.email = stateContext?.merchantParams.email;
    props.values.idType = stateContext?.merchantParams.idType;
    props.values.DNI = stateContext?.merchantParams.identification;
    props.values.firstName = stateContext?.merchantParams.name?.split(' ')[0];
    props.values.lastName = stateContext?.merchantParams.name?.split(' ')[1];
  }

  const getCoordsByGetConfig = async () => {
    let coords:any[] = [];
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const requestResponse = await request.get(`ampmProduct/getConfigStores`, {}, {'Accept': 'application/json'});

    if (requestResponse.status === HttpStatus.OK) {
      await Promise.all(
        requestResponse.data.map((store:any) => {
          let dataGeo = store.storeCoordinates.flatMap((cords:any)=>{
            return {lat: cords[1], lng: cords[0]};
          })
          if (Object.keys(dataGeo).length) coords.push(dataGeo);
          infoWareHouse.push({id: store.id, positionWarehouse: dataGeo});
          return coords;
        }));
    }
    setMerchantPolygons(coords)
    return coords;
  }
  
  const getStoreId = async (userLocation:any) => {
    let storePolygon:any = [];

    infoWareHouse.length && infoWareHouse.map((info:any) => {
      const polygonsAreas:any = new google.maps.Polygon({
        paths: info.positionWarehouse
      });
    
      storePolygon.push({id: info.id, polygonStore: polygonsAreas});
    });

    if (storePolygon.length) {
      const getId = storePolygon.filter((store:any) => {
        if (google.maps.geometry.poly.containsLocation(userLocation, store.polygonStore)) return store.id
      });
      props.setStoreId(getId[0]?.id);
      props.handleChange({
        target: {
          name: 'idStore',
          value: getId[0]?.id
        }
      });
    }
  }
  
  const validateEnabledStep = () => {
    const enabled = !Object.keys(props.errors).length;
    if (!props.stepEnabled && enabled && Object.keys(props.values).length && isInRange) props.setStepEnabled(true);
    if (props.stepEnabled && (!enabled || !Object.keys(props.values).length)) props.setStepEnabled(false);
  }

  return (
    <>
      {showShipmentAddress ? <Address setStore={props.setStoreId} setShowShipment={setShowShipmentAddress} getPolygons={getCoordsByGetConfig} setUserInRange={setIsInRange} setAddressList={setAddressList} handleChange={props.handleChange} values={props.values}/> :
        <form className={'Form pickup-form'}>
          {isInRange ? (props.values.isInRange = true) : !props.values.isInRange && props.errors.isInRange && (
            <span className={'Form-input-error'}>{<FormattedMessage id={props.errors.isInRange}/>}</span>
          )}

          <div className={'map'}>
           {addressList.length > 0 && !props.addAddress  && <span style={{textDecoration: 'underline', color: '#1890ff', fontSize: '1.1rem'}} onClick={() => setShowShipmentAddress(true)}>{intl.formatMessage({id: 'Ver direcciones'})}</span>}
            <input
              type={'text'}
              value={address}
              ref={searchInput}
              style={{color:`${stateContext.currentMerchant.styles?.color1}`}}
              className={'shipment-form-input'}
              onChange={(e)=>setAddress(e.target.value)}
              placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_SEARCH'})}
            />
            <AMPMMap zoom={zoom}
                     poly={merchantPolygons}
               search={searchInput}
               setAddress={setAddress}
               getStoreId={getStoreId}
               setUserInRange={setIsInRange}
               handleChange={props.handleChange}
               getPolygons={getCoordsByGetConfig}
               state={stateContext.currentMerchant}
               valueLocation={props.values.location}
            />
          </div>
          
          <Form.Item>
            <Input
              maxLength={60}
              name={'address'}
              value={props.values.address}
              onChange={(event:any) => props.handleChange(event)}
              placeholder={intl.formatMessage({id: 'SHIPMENT__FORM_ADDRESS'})}
              style={{color:`${stateContext.currentMerchant.styles?.color1}`}}
              className={`shipment-form-input-margin ${props.errors.address && 'shipment-form-input__error'}`}
            />
            
            {props.errors.address && (
              <span className={'input-error-phone Form-input-error'}>{<FormattedMessage id={props.errors.address}/>}</span>
            )}
          </Form.Item>
          {
            props.addAddress?
              <Form.Item>
                <Input
                  maxLength={60}
                  name={'alias'}
                  value={props.values.alias}
                  onChange={(event: any) => props.handleChange(event)}
                  placeholder={'Alias'}
                  style={{color: `${stateContext.currentMerchant.styles?.color1}`}}
                  className={`shipment-form-input-phone ${props.errors.alias && 'shipment-form-input__error'}`}
                />

                {props.errors.address && (
                  <span className={'input-error-phone Form-input-error'}>{<FormattedMessage
                    id={props.errors.address}/>}</span>
                )}
              </Form.Item>
            :
              <></>
          }
          
          <Form.Item>
            <div style={{color:`${stateContext.currentMerchant.styles?.color1}`}} className={`shipment-form-input-phone ${props.errors.phoneNumber && 'shipment-form-input__error'}`}>
              <PhoneInput
                country={'cr'}
                placeholder={'+506 8588-0000'}
                inputClass={`Form-phone-input`}
                value={props.values.phoneNumber}
                inputProps={{name: 'phoneNumber'}}
                onChange={(values:any, country:any, event:any) => {
                  event.persist();
                  if (event.target) {
                    props.values['country'] = country;
                    props.handleChange(event);
                  }
                }}
              />
              
              {props.errors.phoneNumber && (
                <span className={'input-error-phone Form-input-error'}>{<FormattedMessage id={props.errors.phoneNumber}/>}</span>
              )}
            </div>
          </Form.Item>
          {
            props.addAddress?
              <Form.Item>
                <div className={'addAddress-Button-Container'}>
                <button disabled={!props.values.address?.length||!props.values.alias?.length||props.errors.phoneNumber} className={'AddAddress-product-Purchase'} style={{marginRight:"15px"}} onClick={(e)=>props.handleSubmit(e)}>Agregar</button>
                <button className={'AddAddress-product-Purchase'} onClick={(e)=> {
                  e.preventDefault()
                  props.setNewAddress && props.setNewAddress(false);
                  props.setSelectValue && props.setSelectValue('1')
                }}>Salir</button>
                </div>
              </Form.Item>
            :
              <></>
          }
        </form>
      }
    </>
  );
}



