import React from 'react';
import {Skeleton} from 'antd';
import './SkeletonBillingInfo.css';

export function SkeletonBillingInfo() {
  return (
    <div className={'skeletonBillingInfo'}>
      <Skeleton.Input active={true} className={'skeletonBilling'}/>
      <Skeleton.Input active={true} className={'skeletonBillingPrice'}/>
      <Skeleton.Input active={true} className={'skeletonBillingForm'}/>
    </div>
  )
}
