import './NewCart.css'
import {Context} from '../../Store';
import {Utils} from '../../../services/Utils.class';
import {FormattedMessage, useIntl} from 'react-intl';
import {RightCircleOutlined} from '@ant-design/icons';
import React, {useContext, useEffect, useState} from 'react';

export function LoyaltyPoints (props:{
  ivaPrice:any,
  totalPrice:any,
  subTotalPrice:any,
  setDiscount:Function,
}) {

  const intl = useIntl();
  const [state, dispatch] = useContext(Context);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [selectedLoyaltyPoints, setSelectedLoyaltyPoints] = useState(0);
  const [actualLoyaltyPoints, setActualLoyaltyPoints] = useState(0);

  useEffect(() => {
    if (state.merchantParams?.loyaltyPoints) setActualLoyaltyPoints(state.merchantParams.loyaltyPoints);
  }, []);

  const onChangeLoyaltyPoints = (value:number) => {
    const selectedPoints = Number(value) > props.totalPrice ? props.totalPrice : Number(value);
    if (percentTotalDiscount(value)) {
      setSelectedLoyaltyPoints(selectedPoints);
      setActualLoyaltyPoints(actualLoyaltyPoints - selectedPoints);
      props.setDiscount(selectedPoints);
    }
  };

  const percentTotalDiscount = (selectedLoyaltyPoints:number) => {
    let isValid = false;
    const totalPriceWhitDiscount = ((props.totalPrice / 100) * 80);

    if (actualLoyaltyPoints > 0 && totalPriceWhitDiscount >= selectedLoyaltyPoints) {
      isValid = true
    } else {
      Utils.showNotificationMessage('info', intl.formatMessage({id: 'CASHIER___LABEL_LOYALTY_POINTS_ERROR'}), `${intl.formatMessage({id: 'CASHIER___LOYALTY_POINTS_ERROR_PICK'})} ${Math.floor(totalPriceWhitDiscount)}`);
    }

    return isValid;
  }

  return (
    <div className={'Cart-loyaltyPoints'}>
      <span className='Span-total-loyalty-points'>
        <FormattedMessage
          id={'CASHIER_CART__LABEL_LOYALTY_POINTS'}/> {state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(actualLoyaltyPoints, state.ruleParams?.urlStockEnable ? 0 : 2)}
      </span>

      <span className='Span-total-loyalty-points'>
        Descuento máximo aplicable &nbsp;
        {state.currentMerchant.isoCurrency.symbol}{Utils.formatMoney(props.totalPrice > state.merchantParams.loyaltyPoints ? state.merchantParams.loyaltyPoints : ((props.totalPrice / 100) * 80), state.ruleParams?.urlStockEnable ? 0 : 2)}
      </span>

      <div className={'Cart-loyaltyPoints-input'}>
        <div className={'New-cart-Loyalty-Input-C'} >
          {state.currentMerchant.isoCurrency.symbol}
          <input className={'Cart-input-addPoints'} placeholder={'Cantidad'} value={loyaltyPoints} onChange={(e:any) => {setLoyaltyPoints(e.target.value)}}/>
        </div>
        <RightCircleOutlined className={'loyalty-right-icon'} onClick={() => {onChangeLoyaltyPoints(loyaltyPoints)}}/>
      </div>
    </div>
  )
}