import './stock/AMPMFront/EditingOrder.css';
import {Context} from '../Store';
import {NotFound} from '../NotFound';
import {useParams} from 'react-router';
import {Steps, Result, Button} from 'antd';
import {EditingOrder} from './stock/AMPMFront/EditingOrder';
import {useHistory} from 'react-router-dom';
import {UseForm} from '../../services/Form';
import {BarsStep} from '../spinner/BarsStep';
import {LeftOutlined} from '@ant-design/icons';
import * as HttpStatus from 'http-status-codes';
import {Utils} from '../../services/Utils.class';
import {WarningOutlined} from '@ant-design/icons';
import {FormattedMessage, useIntl} from 'react-intl';
import shipments from '../../integrations/shipments';
import processors from '../../integrations/processors';
import {ProductUtils} from '../../services/Product.utils';
import {HttpRequest} from '../../services/HttpRequest.class';
import {FooterCashier} from '../footerCashier/FooterCashier';
import {ShowAddress} from './delivery/showAddress/ShowAddress';
import {CallbackPickUp} from './delivery/pickup/CallBackPickUp';
import {SkeletonPayU} from '../skeleton/skeletonPayU/SkeletonPayU';
import {PickupValidators} from './delivery/pickup/PickupValidators';
import callbacks from '../../integrations/shipments/indexCallbacks';
import {SkeletonToroso} from '../skeleton/skeletonToroso/SkeletonToroso';
import {SkeletonPayUpse} from '../skeleton/skeletonPayUpse/SkeletonPayUpse';
import shipmentsValidators from '../../integrations/shipments/indexValidators';
import React, {UIEvent, useContext, useEffect, useState, Suspense} from 'react';
import {SkeletonBillingInfo} from '../skeleton/skeletonBilling/SkeletonBillingInfo';
import {SkeletonMiscellaneuos} from '../skeleton/skeletonMiscellaneuos/SkeletonMiscellaneuos';

export function PayOrderAMPM () {
  const intl = useIntl();
  const history = useHistory();
  const windowVar: Window = window;
  const merchant = Utils.getMerchantByUrl();
  const [state, dispatch] = useContext(Context);
  const [copied, setCopied] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [logo, setLogo] = useState(<i
    className={'medium material-icons Cashier-header-content-logo'}>store</i>);

  let errorsShipment = '';
  const {orderId} = useParams();
  const [price, setPrice] = useState(0);
  const [current, setCurrent] = useState(0);
  const [storeId, setStoreId] = useState(0);
  const [decimals, setDecimals] = useState(2);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [orderType, setOrderType] = useState(0);
  const [virtual, setVirtual] = useState(false);
  const [processor, setProcessor] = useState('');
  const [createdAt, setCreatedAt] = useState(0);
  const [inSubmit, setInSubmit] = useState(false);
  const [conflict, setConflict] = useState(false);
  const [validStock, setValidStock] = useState(true);
  const [blockOrder, setBlockOrder] = useState(false);
  const [shippingCost, setShippingCost] = useState(0);
  const [virtualCurrent, setVirtualCurrent] = useState(current);
  const [pickupStores, setPickupStores] = useState([]);
  const [stepEnabled, setStepEnabled] = useState(false);
  const [orderShipment, setOrderShipment] = useState<any>(null);
  const [loyaltyPoints, setLoyaltyPoints] = useState(0);
  const [scrollBottom, setScrollBottom] = useState(false);
  const [renderEnabled, setRenderEnabled] = useState(true);
  const [pickupEnabled, setPickupEnabled] = useState(false);
  const [shipmentReset, setShipmentReset] = useState(false);
  const [editableOrder, setEditableOrder] = useState(false);
  const [customerAddress, setCustomerAddress] = useState([]);
  const [selectedPickup, setSelectedPickup] = useState(true);
  const [shipmentProvider, setShipmentProvider] = useState('');
  const [pickupPointsPath, setPickupPointsPath] = useState('');
  const [redirectEditing, setRedirectEditing] = useState(false);
  const [shipmentEnabled, setShipmentEnabled] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(false);
  const [processorResponded, setProcessorResponded] = useState(false);
  const [shipmentProductPrice, setShipmentProductPrice] = useState('');
  const [shippingIsNecessary, setShippingIsNecessary] = useState(false);
  const [urlStockEnable, setUrlStockEnable] = useState(false);
  const [priceWithDiscount, setPriceWithDiscount] = useState(0);
  const [stockReaddy, setStockReaddy] = useState(false);
  const [discountPercent, setDiscountPercent] = useState(0)
  const [customerId, setCustomerId] = useState('')

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get('id');

  const setShowLoyaltyParams = (params: boolean) => {
    dispatch({
      type: 'CURRENT-POINTS',
      payload: params
    });
  };

  useEffect(() => {
    dispatch({
      payload: merchant,
      type: 'ID-MERCHANT'
    });
    document.title = state.currentMerchant.id.toUpperCase();
    const favicon: any = document.getElementById('favicon');

    if (favicon) {
      favicon.href = `${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${state.currentMerchant.logoId}`;
    }

    if (state.merchantParams?.identification) getLoyaltyPoints('ampmProduct/getLoyaltyClient').then();


  }, [id]);


  useEffect(() => {
    if (state.merchantId) {
      if (id && id.trim().length) {
        setCustomerId(id)
        getDecipherInfo(id).then();

        urlParams.delete('id');
        history.replace({
          search: urlParams.toString()
        });
      }
      getOrder().then(() => getRules().then(() => isValidStock().then()));
    }
  }, [state.merchantId]);

  useEffect(() => {
    windowVar.addEventListener('beforeunload', alertUser);
    windowVar.addEventListener('unload', handleTabClosing);
    return () => {
      windowVar.removeEventListener('beforeunload', alertUser);
      windowVar.removeEventListener('unload', handleTabClosing);
    };
  }, [processorResponded]);

  useEffect(() => {
    if (state.showLoyaltyPoints) setShowLoyaltyParams(false);
  }, []);

  useEffect(() => {
    if (pickupEnabled && pickupPointsPath) {
      setRenderEnabled(false);
      getPickupPoints(pickupPointsPath).then();
    }
  }, [pickupPointsPath, state.merchantParams?.encryptId]);

  useEffect(() => {
    if (urlStockEnable && !stockReaddy) {
      setRedirectEditing(true);
      const getIdStore = async () => {
        const CustomerAddress: any = await getCustomerAddress(customerId);
        if (orderShipment) {
          if (orderShipment.pickup) {
            setSelectedPickup(true);
            setSelectedShipment(false);
            setStoreId(orderShipment.pickup.code);
            setStepEnabled(true);
          }
          else{
            setSelectedPickup(false);
            setSelectedShipment(true);
            setStoreId(Number(orderShipment.shipment.address.idStore));
          }
        }
        else if (CustomerAddress) {
          setSelectedPickup(false);
          setSelectedShipment(true);
          setStoreId(Number(CustomerAddress[CustomerAddress.length-1].address.idStore));
        } else {
          setSelectedPickup(true);
          setSelectedShipment(false);
          setStoreId(18);
          setStepEnabled(true);
        }
      };
      getIdStore();
      setLoading(false);
      setStockReaddy(true);
    }
  }, [urlStockEnable]);

  const {Step} = Steps;
  const {
    values: pickupValues,
    errors: pickupErrors,
    setErrors: pickupSetErrors,
    handleChange: handleChangePickup,
    handleSubmit: handleSubmitPickup
  } = UseForm(PickupValidators, CallbackPickUp, (data: any) => {
    updateOrder(data).then();
  }, current);

  const {
    values: shipmentValues,
    errors: shipmentErrors,
    setValues: shipmentSetValues,
    setErrors: shipmentSetErrors,
    handleChange: handleChangeShipment,
    handleSubmit: handleSubmitShipment
  } = UseForm(shipmentsValidators[shipmentProvider], callbacks[shipmentProvider], (data: any) => {

    updateOrder(data).then(() => setShippingCost(Number(shipmentProductPrice)));
    setShipmentData(shipmentValues);
    //if (merchant == 'ampm') saveNewAddress(data).then();
  }, current);

  const getCustomerAddress = async (id:string) => {
    const request = new HttpRequest(`https://ampm.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const merchantRequest = await request.get(`shipmentAddress/${id?.length?id : state.merchantParams?.encryptIdCustomer}`, {}, {Accept: 'application/json'});
    if (merchantRequest.status === HttpStatus.OK) {
      if (Object.keys(merchantRequest.data).length) {
        setCustomerAddress(merchantRequest.data);
        return merchantRequest.data;
      }
    }
  };

  const getDecipherInfo = async (id: string) => {
    const request = new HttpRequest(`${process.env.REACT_APP_API_HOST}` || '');
    const decipherDataRequest: any = await request.get(`encrypt/${id}`);

    if (decipherDataRequest.status === HttpStatus.OK) {
      const decipherData = JSON.parse(decipherDataRequest.data.decipherData);
      if (decipherDataRequest.data.createdAt) setCreatedAt(decipherDataRequest.data.createdAt);
      if (decipherData.identification) {
        let params = {
          encryptIdCustomer: id,
          name: decipherData.name,
          email: decipherData.email,
          idType: decipherData.idType,
          period: decipherData.period,
          encryptId: decipherData.encryptId,
          tributeType: decipherData.tributeType,
          identification: decipherData.identification,
          invoice: decipherData.invoice ? decipherData.invoice : '',
          meterNum: decipherData.meterNum ? decipherData.meterNum : '',
          suscriptor: decipherData.suscriptor ? decipherData.suscriptor : '',
          hyphenatedIdentification: decipherData.hyphenatedIdentification ? decipherData.hyphenatedIdentification : ''
        };

        if (decipherData.contract) {
          params = Object.assign(params, {
            contract: decipherData.contract
          });
        }

        setMerchantParams(params);
        return params;
      }
    } else {
      setMerchantParams({
        encryptIdCustomer: id
      });
    }
  };

  const setMerchantParams = (params: object) => {
    dispatch({
      type: 'ADD-MERCHANT-PARAMS',
      payload: params
    });
  };

  const saveNewAddress = async (data: any) => {
    let isSave = false;

    const newCustomerAddress: any = {
      merchantId: merchant,
      email: data.delivered.shipment.clientProfile.email,
      lastName: data.delivered.shipment.clientProfile.lastName,
      firstName: data.delivered.shipment.clientProfile.firstName,
      phoneNumber: data.delivered.shipment.clientProfile.phoneNumber,
      address: {
        city: data.delivered.shipment.address.city,
        state: data.delivered.shipment.address.state,
        alias: data.delivered.shipment.address.alias,
        idStore: data.delivered.shipment.address.idStore,
        address: data.delivered.shipment.address.address,
        location: data.delivered.shipment.address.location,
        country: state.currentMerchant.country.countryCode,
        district: data.delivered.shipment.address.district,
        postalCode: data.delivered.shipment.address.postalCode
      }
    };

    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const merchantRequest = await request.post(newCustomerAddress, `shipmentAddress/${state.merchantParams?.encryptIdCustomer}`, {Accept: 'application/json'});

    if (merchantRequest.data == true && !isSave) {
      Utils.showNotificationMessage('info', intl.formatMessage({id: 'SHIPMENT__ADDRESS_SAVED'}), intl.formatMessage({id: 'SHIPMENT__ADDRESS_SAVE'}));
      isSave = true;
    }

    return;
  };

  const setShipmentData = (data: any) => {
    localStorage.setItem('shipment', JSON.stringify(data));
  };

  const handleTabClosing = () => {
    return false;
  };

  const alertUser = (event: any) => {
    if (!processorResponded) {
      event.preventDefault();
      event.returnValue = '';
      return '';
    }
  };

  const isValidStock = async () => {
    const isValid = await ProductUtils.validStock(merchant, dispatch, false);

    if (isValid) {
      merchantInfo().then();
    }

    merchant == 'ampm' ? setValidStock(true) : setValidStock(isValid);
  };

  const merchantInfo = async () => {
    const request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
    const merchantRequest = await request.get(`merchant/${merchant}`);
    if (merchantRequest.status === HttpStatus.OK) {
      const key = merchantRequest.data.username;
      const file = merchantRequest.data.logoId;

      setLogo(
        <img
          alt={'logo'}
          className={'Cashier-header-content-logo'}
          src={`${process.env.REACT_APP_S3_URL}/${key}/${file}`}
        />
      );

      merchantRequest.data.links = Object.assign(state.currentMerchant.links, merchantRequest.data.links || {});

      dispatch({
        type: 'CURRENT-MERCHANT',
        payload: merchantRequest.data
      });
    } else if (merchantRequest.status === HttpStatus.NOT_FOUND) {
      Utils.showNotificationMessage(
        'info',
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__INFO_NOTIFICATION_TITLE_BUSINESS_NOT_FOUND'}),
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__INFO_NOTIFICATION_MESSAGE_BUSINESS_NOT_FOUND'})
      );
    } else {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__ERROR_NOTIFICATION_TITLE_FETCHING_BUSINESS'}),
        intl.formatMessage({id: 'CASHIER_STOCK_CASHIER__ERROR_NOTIFICATION_MESSAGE_FETCHING_BUSINESS'})
      );
    }
  };

  const getOrder = async () => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const orderRequest = await request.get(orderId, {}, {
      Accept: 'application/json'
    });

    let order = [];
    if (orderRequest.status === HttpStatus.OK) {
      order = orderRequest.data;

      setPrice(order.total);
      setProducts(order.products);
      setProcessor(order.processor);
      setPaymentMethod(order.paymentMethod);
      setShipmentProvider(order.shipmentProvider);
      setShippingIsNecessary(order.shippingIsNecessary);

      if (order.type) {
        setOrderType(order.type);
        setBlockOrder(true);
      }

      if (order.delivered) {
        setOrderShipment(order.delivered)
      }

    } else if (orderRequest.status === HttpStatus.NOT_FOUND) {
      Utils.showNotificationMessage(
        'info',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_TITLE_ORDER_NOT_FOUND'}),
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_MESSAGE_ORDER_NOT_FOUND'})
      );
    } else if (orderRequest.status === HttpStatus.CONFLICT) {
      setConflict(true);
      setBlockOrder(true);
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_TITLE_CONFLICTED_ORDER'}),
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_MESSAGE_CONFLICTED_ORDER'})
      );
    } else {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_TITLE_FETCHING_ORDER'}),
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_MESSAGE_FETCHING_ORDER'})
      );
    }
  };

  useEffect(() => {
    urlStockEnable && setRedirectEditing(true);
    urlStockEnable ? setDecimals(0) : setDecimals(2);
  }, [urlStockEnable]);

  const getRules = async () => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const rulesRequest = await request.get('rule');

    if (rulesRequest.status === HttpStatus.OK) {
      const dataResponse = rulesRequest.data;

      dataResponse.map((current: any) => {
        if (current.name === 'urlStockEnable') setUrlStockEnable(true);
        if (current.name === 'pickupEnabled') setPickupEnabled(true);
        if (current.name === 'editableOrder') setEditableOrder(true);
        if (current.name === 'shipmentEnabled') setShipmentEnabled(true);
        if (current.name === 'getPickupPointsPath') setPickupPointsPath(current.value.path);
        if (current.name === 'shipmentProductPrice') setShipmentProductPrice(current.value);
      });
      setLoading(false);
    }
  };

  const updateOrder = async (data: any) => {
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    if (Object.keys(data).length) {
      setMerchantParams({
        ...state.merchantParams,
        phoneNumber: data.delivered?.shipment?.clientProfile?.phoneNumber
      });
      await request.put(orderId, data);
    }
  };

  const getPickupPoints = async (path: string) => {
    const request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
    const requestResponse = await request.get(path);

    if (requestResponse.status === HttpStatus.OK) {
      const merchantData = await request.get(`merchant/${merchant}`);
      if (Object.keys(merchantData).length && Object.keys(requestResponse.data).length) {
        merchantData.data.stores = requestResponse.data;
        dispatch({
          payload: merchantData.data,
          type: 'CURRENT-MERCHANT'
        });
        setPickupStores(requestResponse.data);
      }
    } else if (requestResponse.status === HttpStatus.NOT_FOUND) {
      Utils.showNotificationMessage(
        'info',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_TITLE_PICKUP_POINTS_NOT_FOUND'}),
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_MESSAGE_PICKUP_POINTS_NOT_FOUND'})
      );
    } else {
      Utils.showNotificationMessage(
        'error',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_TITLE_FETCHING_PICKUP_POINTS'}),
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__ERROR_NOTIFICATION_MESSAGE_FETCHING_PICKUP_POINTS'})
      );
    }
    setRenderEnabled(true);
  };

  const getLoyaltyPoints = async (path: string) => {
    const request = new HttpRequest(process.env.REACT_APP_API_HOST || '');
    const loyaltyPointsRequest = await request.get(`${path}/${state.merchantParams.identification}/1`);

    if (loyaltyPointsRequest.status === HttpStatus.OK) {
      const loyaltyPointsData = loyaltyPointsRequest.data;
      if (Object.keys(loyaltyPointsData).length && loyaltyPointsData.colones_acumulados) {
        setLoyaltyPoints(Number(loyaltyPointsData.colones_acumulados.replaceAll(',', '')));
      }
    }
  };

  if (loading) {
    return <BarsStep merchant={merchant}/>;
  }

  let content = <NotFound/>;

  const isOrderDelivered = async (orderId: string) => {
    const orderFinished: number = 5;
    let isOrderReadyToDelivery: boolean = true;
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const shipmentRequest = await request.get(`shipment/${orderId}`, {}, {'Accept': 'application/json'});

    if (shipmentRequest.status == HttpStatus.OK) {
      let orderDelivered = shipmentRequest.data?.orderShipment;

      if (orderDelivered != undefined) {
        if (Object.keys(orderDelivered).length && orderDelivered.status < orderFinished) {
          isOrderReadyToDelivery = false;
        }
      }
    }

    return isOrderReadyToDelivery;
  };

  const redirectTracking = async () => {
    const isDelivered: boolean = await isOrderDelivered(orderId);
    const request = new HttpRequest(`https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const shipmentRequest = await request.get(`shipment/${orderId}`, {}, {'Accept': 'application/json'});

    if (!isDelivered && (shipmentRequest.status == HttpStatus.OK && !shipmentRequest.data.feedback)) {
      setTimeout(() => {
        setProcessorResponded(true);
        windowVar.location.href = `https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}/shipment/${orderId}`;
      }, 3000);
    }
  };

  if (blockOrder) {
    if (orderType === 1) {
      redirectTracking().then();

      setLoading(true);
      setProcessorResponded(true);
      Utils.showNotificationMessage(
        'info',
        intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_TITLE_ORDER_CONFLICT'}),
        (conflict ?
            intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_MESSAGE_ORDER_CONFLICT_01'}) :
            intl.formatMessage({id: 'CASHIER_PAY_ORDER__INFO_NOTIFICATION_MESSAGE_ORDER_CONFLICT_02'})
        )
      );

      setTimeout(() => {
        windowVar.location.href = `https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}/transaction/FAC/status/${orderId}`;
      }, 3000);
    }
  }

  if (!validStock) {
    content = (
      <Result
        status={'warning'}
        title={intl.formatMessage({id: 'CASHIER_PAY_ORDER__LABEL_WARNING_CART_CHANGES'})}
        extra={
          <Button type={'primary'} key={'closeBTN'}
                  onClick={() => windowVar.location.href = editableOrder ? `https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}/order/${orderId}` : `https://${merchant}.${process.env.REACT_APP_APP_DOMAIN}/0?showCart=1`}><FormattedMessage
            id={'CASHIER_PAY_ORDER__BUTTON_BACK_TO_CART'}/></Button>
        }
      />
    );
  }

  if (validStock && !blockOrder && processors.hasOwnProperty(processor) && renderEnabled) {
    const ProcessorComponent = processors[processor];
    content = (
      <>
        <ProcessorComponent
          price={price}
          priceWithDiscount={priceWithDiscount}
          orderId={orderId}
          merchant={merchant}
          inSubmit={inSubmit}
          processorName={processor}
          setInSubmit={setInSubmit}
          scrollBottom={scrollBottom}
          shippingCost={shippingCost}
          paymentMethod={paymentMethod}
          setValidStock={setValidStock}
          setProcessorResponded={setProcessorResponded}
          discountPercent={discountPercent}/>
      </>
    );

    let handleSubmit: any;
    if (selectedPickup || (pickupEnabled && !shipmentEnabled)) {
      handleSubmit = handleSubmitPickup;
    }

    if (selectedShipment || (!pickupEnabled && shipmentEnabled)) {
      handleSubmit = handleSubmitShipment;
    }

    let EditingComponent = <></>;
    let DeliveryComponent = <></>;
    if (shippingIsNecessary && pickupEnabled || shippingIsNecessary && shipmentEnabled) {
      let PickupComponent = <></>;
      let ShipmentComponent = <></>;
      const disabled = selectedPickup || (pickupEnabled && !shipmentEnabled) ? !stepEnabled : false;
      const isFacProcessor = processor === 'PowerTranz' || processor === 'FAC' || processor === 'FACT03' || processor === 'FACT06';
      const stepTitle = pickupEnabled && shipmentEnabled ? 'CASHIER_DELIVERY__STEP_LABEL' : (pickupEnabled ? 'CASHIER_DELIVERY__STEP_LABEL_PICKUP' : 'CASHIER_DELIVERY__STEP_LABEL_SHIPMENT');

      if (merchant != 'ampm') {
        if (shipmentEnabled && shipments.hasOwnProperty(shipmentProvider)) {
          const ShipmentProvider = shipments[shipmentProvider];
          ShipmentComponent = <ShipmentProvider
            reset={shipmentReset}
            errors={shipmentErrors}
            setStoreId={setStoreId}
            values={shipmentValues}
            stepEnabled={stepEnabled}
            setValues={shipmentSetValues}
            setErrors={shipmentSetErrors}
            setStepEnabled={setStepEnabled}
            handleChange={handleChangeShipment}
            handleSubmit={handleSubmitShipment}
          />;

          DeliveryComponent = ShipmentComponent
        }
      }

      const btnPay = <button
        disabled={disabled}
        onClick={() => {
          handleSubmit();

          if (stepEnabled) {
            setVirtual(true);
            setCurrent(virtualCurrent);
            setVirtualCurrent(1);
          }
        }}
        className={'btn btn-secondary btn-left blue lighten-2 green right Button-Pay-Card'}>{editableOrder ? intl.formatMessage({id: 'CASHIER_DELIVERY__LABEL_ORDER'}) : intl.formatMessage({id: 'CASHIER_DELIVERY__LABEL_PAY'})}
      </button>;


      if ((selectedShipment || (!pickupEnabled && shipmentEnabled)) && shipmentProductPrice && !shippingCost) {
        setShippingCost(Number(shipmentProductPrice));
      }

      if ((selectedPickup || (pickupEnabled && !shipmentEnabled)) && shippingCost) setShippingCost(0);

      const currentSteps: any = [{
        title: intl.formatMessage({id: stepTitle}),
        content:
          <>
            {DeliveryComponent}

            {merchant === 'ampm' ? '' : btnPay}

            <FooterCashier state={state} fac={isFacProcessor}/>
          </>
      },
        {
          title: intl.formatMessage({id: 'CASHIER_DELIVERY__LABEL_PAY'}),
          content: content
        }
      ];

      if (editableOrder || redirectEditing) {
        currentSteps.splice(1, 0, {
          title: <FormattedMessage id={'CASHIER_EDIT_ORDER__STEP'}/>,
          content: <>{EditingComponent}</>
        });
      }

      content = (
        <>
          {urlStockEnable && current === 2 ?
            <div className={'AMPM-pay-header'}>
              <div className={'AMPM-pay-paginator'} onClick={() => {
                setCurrent(1);
                setRedirectEditing(true);
              }}>
                <LeftOutlined className={'AMPM-pay-paginator-left'}/>
                &nbsp;
                <p className={'AMPM-pay-paginator-text'}>
                  Catálogo&nbsp;/&nbsp;Pago
                </p>
              </div>
              <p className={'AMPM-pay-header-subtitle'}>Monto a pagar</p>
              <p className={'AMPM-pay-header-amount'}>
                {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(priceWithDiscount?priceWithDiscount:price, decimals)}
              </p>
            </div>
            :
            urlStockEnable ?
              <>
              </>
              :
              <Step className={'StepsManager'} current={current} onChange={(newCurrent) => {
                handleSubmit && handleSubmit();

                if (stepEnabled) {
                  setVirtual(true);
                  setVirtualCurrent(newCurrent);
                  newCurrent == 2 ? setShowLoyaltyParams(true) : setShowLoyaltyParams(false);
                }
              }}>
                {currentSteps.map((item: { title: string }) => (
                  <Step key={item.title} title={item.title}/>
                ))}
              </Step>}
          {currentSteps[ current ].content}
        </>
      );

      const errors = selectedPickup || (pickupEnabled && !shipmentEnabled) ? pickupErrors : (selectedShipment || (!pickupEnabled && shipmentEnabled) ? errorsShipment : {});
      if (!Object.keys(errors).length && virtual && stepEnabled) {
        setCurrent(virtualCurrent);
        setVirtual(false);
      }
    }
  }

  const changeSkeleton = (processor: any) => {
    if (processor === 'PayUPSE') {
      return <SkeletonPayUpse/>;
    } else if (processor === 'PayU') {
      return <SkeletonPayU/>;
    } else if (processor === 'PowerTranz' || processor === 'FAC' || processor === 'FACT03' || processor === 'FACT06') {
      return <SkeletonBillingInfo/>;
    } else if (processor === 'Miscellaneous') {
      return <SkeletonMiscellaneuos/>;
    } else if (processor === 'Toroso' || processor === 'CGP') {
      return <SkeletonToroso/>;
    }
  };

  let skeleton: any = changeSkeleton(processor);
  const checkCopy = copied ? <i className={'medium material-icons ready'}>check</i> : <></>;

  const handleUIEvent = (event: UIEvent<HTMLDivElement>) => {
    const scroll: any = Utils.handleUIEventScroll(event);
    setScrollBottom(Boolean(scroll));
  };

  if (orderType === 3) {
    content = (
      <>
        <p className={'center PayOrder-expired'}>
          <WarningOutlined style={{fontSize: '66px', color: '#FFD700'}}/>
          <p><b>
            <FormattedMessage id={'CASHIER_PAY_ORDER__MESSAGE_EXPIRED_ORDER'}/>
          </b></p>
        </p>
        <p className={'center PayOrder-expiredText'}>
          <FormattedMessage id={'CASHIER_PAY_ORDER__MESSAGE_EXPIRED_ORDER_TEXT'}/>
        </p>
      </>
    );
  }

  const header =
    <>
      {urlStockEnable && current === 2 ?
        <div className={'Editing-header-container'}>
          <div className={'Editing-header-subContainer'}>
            <img
              alt={'logo'}
              className={'Editing-logo'}
              src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${state.currentMerchant.logoId}`}
            />
          </div>
          <div className={'Editing-header-subContainer'}>
          </div>
        </div>
        : merchant === 'belcacr' ? <>
            <div className={'Max-header-container'}>
              <div className={'Max-header-subContainer'}>
                <img
                  alt={'logo'}
                  className={'Max-logo'}
                  src={`${process.env.REACT_APP_S3_URL}/${state.currentMerchant.username}/${state.currentMerchant.logoId}`}
                />
              </div>
              <div className={'Max-header-subContainer'}>
              </div>
            </div>
            {merchant !== 'belcacr' ?
              <>
                <p
                  style={urlStockEnable ? (current != 2 ? {display: 'none'} : {display: 'initial'}) : {textAlign: 'center'}}
                  className={'Max-Method-detail'}>{paymentMethod}</p>
                <p className={`Max-price-detail`}>
                  {state.currentMerchant.isoCurrency.symbol}{merchant === 'belcacr' ? Utils.formatMoney(price) : Utils.formatMoney(priceWithDiscount > 0 ? priceWithDiscount : price + shippingCost, decimals)}
                </p>
              </>:
              <>
                <p
                  style={urlStockEnable ? (current != 2 ? {display: 'none'} : {display: 'initial'}) : {textAlign: 'center'}}
                  className={'Max-Method-detail'}>{paymentMethod}</p>
                <p className={`Max-price-detail`}>
                  {state.currentMerchant.isoCurrency.symbol}{merchant === 'belcacr' ? Utils.formatMoney(price) : Utils.formatMoney(priceWithDiscount > 0 ? priceWithDiscount : price + shippingCost, decimals)}
                </p>
              </>
            }
          </>
          :
          <>
            <p
              style={urlStockEnable ? (current != 2 ? {display: 'none'} : {display: 'initial'}) : {textAlign: 'center'}}
              className={'PayOrder-paymentMethod center'}>{paymentMethod}</p>
            <p
              style={urlStockEnable ? (current != 2 ? {display: 'none'} : {display: 'initial'}) : {display: 'initial'}}
              className={'PayOrder-amount center'}>
          <span className={`PayOrder-price-detail`}>
            {state.currentMerchant.isoCurrency.symbol + Utils.formatMoney(priceWithDiscount > 0 ? priceWithDiscount : price + shippingCost, decimals)}
          </span>
            </p>
          </>
      }
    </>;


  let isValid = false;
  let bodyExpired = <></>;
  if (merchant == 'pumacolombia') {
    isValid = state.merchantParams?.encryptIdCustomer ? Utils.encryptIsValid(createdAt ? createdAt : state.merchantParams.createdAt, 15) : true
    bodyExpired = (
      <div className={'Mainpage-invalidEncrypt'}>
        <p className={'center PayOrder-expired'}>
          <WarningOutlined style={{fontSize: '66px', color: '#FFD700'}}/>
          <p><b>
            <FormattedMessage id={'CASHIER_PAY_ORDER__MESSAGE_EXPIRED_ORDER'}/>
          </b></p>
        </p>
        <p className={'center PayOrder-expiredText'}>
          <FormattedMessage id={'CASHIER_PAY_ORDER__MESSAGE_EXPIRED_ORDER_TEXT'}/>
        </p>
      </div>
    )
  }

  return (
    <div className={'Customer'} onScroll={handleUIEvent}>
      <div id={'page-top'}/>
      {editableOrder || redirectEditing || merchant === 'belcacr' ? <></> :
        <header onClick={() => history.push('/0')}
                style={{backgroundColor: `${state.currentMerchant.styles?.color1}`}}
                className={'Cashier-header-content-icon'}> {logo}
        </header>}

      <Suspense fallback={skeleton}>
        {header}
        {redirectEditing
        &&
        current == 0 || current == 1 ?
          <>
            <EditingOrder
              setDiscountPercent={setDiscountPercent}
              discountPercent={discountPercent}
              pickupValues={pickupValues}
              addressValues={shipmentValues}
              SubmitPickup={handleSubmitPickup}
              SubmitAddress={handleSubmitShipment}
              selectedPickup={selectedPickup}
              ShowAddress={<ShowAddress
                orderShipment={orderShipment}
                selectedPickup={selectedPickup}
                pickupValues={pickupValues}
                addressValues={shipmentValues}
                setSelectedPickup={setSelectedPickup}
                setSelectedShipment={setSelectedShipment}
                PickupHandleChange={handleChangePickup}
                ShipmentHandleChange={handleChangeShipment}
                getCustomerAddress={getCustomerAddress}
                orderId={orderId}
                reset={shipmentReset}
                setStoreId={setStoreId}
                storeId={storeId}
                stepEnabled={stepEnabled}
                listAddress={customerAddress}
                setStepEnabled={setStepEnabled}
                shipmentProvider={shipmentProvider}
                listStores={state.currentMerchant.stores}
              />}
              current={current}
              setIdStore={setStoreId}
              storeId={storeId}
              products={products}
              getOrder={getOrder}
              setCurrent={setCurrent}
              setStepEnabled={setStepEnabled}
              setPriceWithDiscount={setPriceWithDiscount}
            />
          </> : content}
        {current == 0 || current == 1 ? (isValid == undefined || true ? content : bodyExpired) : ''}
      </Suspense>
    </div>
  );

}