import {Utils} from './Utils.class';
import {IncomingHttpHeaders} from 'http';
import * as HttpStatus from 'http-status-codes';
import Axios, {AxiosError, AxiosRequestConfig, AxiosResponse, Method} from 'axios';

export class HttpRequest {
  private method:Method | undefined;
  private readonly initialHeaders = {
    'accept': 'application/json',
    'content-type': 'application/json'
  };

  constructor (private host:string, private aws:boolean = false) {}

  /**
   * Get http action
   * @param path
   * @param params
   * @param headers
   * @param process
   */
  async get (path:string, params:object = {}, headers:IncomingHttpHeaders = this.initialHeaders, process:Function = () => {}) {
    this.method = 'GET';
    return await this.request(path, params, headers, process);
  }

  /**
   * Post http action
   * @param data
   * @param path
   * @param headers
   * @param process
   */
  async post (data:object = {}, path:string = '', headers:IncomingHttpHeaders = this.initialHeaders, process:Function = () => {}) {
    this.method = 'POST';
    return await this.request(path, data, headers, process);
  }

  /**
   * Put http action
   * @param path
   * @param data
   * @param headers
   * @param process
   */
  async put (path:string, data:object = {}, headers:IncomingHttpHeaders = this.initialHeaders, process:Function = () => {}) {
    this.method = 'PUT';
    return await this.request(path, data, headers, process);
  }

  /**
   * Delete http action
   * @param path
   * @param data
   * @param headers
   * @param process
   */
  async delete (path:string, data:object = {}, headers:IncomingHttpHeaders = this.initialHeaders, process:Function = () => {}) {
    this.method = 'DELETE';
    return this.request(path, data, headers, process);
  }

  /**
   * Request http manager
   * @param path
   * @param data
   * @param headers
   * @param process
   */
  private request (path:string, data:any, headers:IncomingHttpHeaders, process:Function):Promise<AxiosResponse> {
    let config:AxiosRequestConfig = {
      method: this.method,
      url: `${this.host}/${path}`,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
        process(percentCompleted);
      }
    };

    if (Object.keys(data).length) {
      let object:'params' | 'data' = (this.method === 'GET') ? 'params' : 'data';
      config[object] = data;
    }

    if (this.aws) {
      const credentials = localStorage.getItem('session') || '{}';
      const awsCredential = JSON.parse(credentials);
      if (awsCredential.access) {
        Axios.defaults.headers.common['Authorization'] = `Bearer ${awsCredential.access.IdToken}`;
      }
    }

    if (Object.keys(headers).length) {
      config['headers'] = headers;
    }

    return new Promise((resolve) => {
      Axios(config).then((response:AxiosResponse) => {
        resolve(response);
      }).catch((error:AxiosError) => {
        const currentError = Utils.objected(error.response);
        const errorResponse = (currentError.hasOwnProperty('status')) ? currentError: {status: HttpStatus.INTERNAL_SERVER_ERROR};
        if (errorResponse.status === HttpStatus.UNAUTHORIZED && this.aws) {
          const credentials = localStorage.getItem('session') || '{}';
          const awsCredential = JSON.parse(credentials);
          if (awsCredential.access) {
            const refreshToken = awsCredential.access.RefreshToken;
            const configRefreshToken:AxiosRequestConfig = {
              method: 'POST',
              url: `${this.host}/auth/refreshToken`,
              data: {
                refreshToken: refreshToken
              }
            };

            Axios(configRefreshToken).then(async (responseRefresh:AxiosResponse) => {
              localStorage.setItem('session', JSON.stringify({
                access: responseRefresh.data.AuthenticationResult
              }));

              resolve(await this.request(path, data, headers, process));
            });
          } else {
            resolve(errorResponse);
          }
        } else {
          if (errorResponse.status != HttpStatus.NOT_FOUND) {
            Utils.sendEmail(JSON.stringify({
              config: config,
              data: JSON.stringify(data),
              path: `${this.host}/${path}`,
              errorResponse: errorResponse
            }), `Error ${this.host}/${path} ${errorResponse.status} in request HttpRequest ${this.method}`, ['it@any2suite.com']).then();
          }

          resolve(errorResponse);
        }
      });
    });
  }
}
