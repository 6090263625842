import React from "react";
import {Skeleton} from 'antd';
import "./SkeletonPayUpse.css";

export function SkeletonPayUpse() {
  return (
    <div className={'skeletonPayUpse'}>
      <Skeleton.Input size={"small"} active={true} className={'skeletonPrice'}/>
      <Skeleton.Image className={'skeletonInputs skeletonInputLogo'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputRemember'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputAdvices'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonInputAdvices'}/>
      <Skeleton.Input size={"large"} active={true} className={'skeletonInputs skeletonForm'}/>
    </div>
  )
}