import CallbackCR from './shipmentCr/CallbackCR';
import CallbackZubale from './zubale/CallbackZubale';
import CallbackDefault from './default/CallbackDefault';
import CallbackCO from "./shipmentCO/CallbackCO";

const callbacks:any = {
  'Vtex': CallbackZubale,
  'CorreosCR': CallbackCR,
  'Moddo': CallbackCO,
  'Zubale': CallbackZubale,
  'Default': CallbackDefault
}

export default callbacks;