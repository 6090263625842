import './Address.css';
import {Button} from 'antd';
import {Skeleton} from 'antd';
import {useIntl} from 'react-intl';
import {Context} from '../../Store';
import * as HttpStatus from 'http-status-codes';
import {EnvironmentOutlined} from '@ant-design/icons';
import React, {useContext, useEffect, useState} from 'react';
import {HttpRequest} from '../../../services/HttpRequest.class';

export function Address(props: {
  values:any,
  setStore:Function,
  getPolygons:Function,
  handleChange:Function,
  setUserInRange:Function,
  setAddressList:Function,
  setShowShipment:Function,
}) {

  const intl = useIntl();
  const [state, dispatch] = useContext(Context);
  const [click, setClick] = useState(-1);
  const [address, setAddress] = useState([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const getAddress = async () => {
      await getCustomerAddress();
    }
    getAddress();
  }, [state.merchantParams?.encryptIdCustomer])
  
  const getCustomerAddress = async () => {
    const request = new HttpRequest(`https://${state.currentMerchant.id}.${process.env.REACT_APP_APP_DOMAIN}` || '');
    const merchantRequest = await request.get(`shipmentAddress/${state.merchantParams?.encryptIdCustomer}`, {}, {Accept: 'application/json'});
    if (merchantRequest.status === HttpStatus.OK) {
      if (Object.keys(merchantRequest.data).length) {
        setAddress(merchantRequest.data);
        props.setAddressList(merchantRequest.data);
        setLoading(true);
      } else props.setShowShipment(false);
    } else props.setShowShipment(false);
  }

  return (
    <form>
      <div className={'Content-address'}>
        <div className={'Content-address-list'}>
          <p className={'Content-address-list-subTitle'}>{intl.formatMessage({id: 'MERCHANT_SHIPMENT_ADDRESS_SUBTITLE'})}</p>
          <>
            {address.length ? address.map((arr:any, idx)=>{
              return (
                <div className={'Content-address-card'} style={{border: click === idx ? '1px solid #91C48A' : '' }} key={idx} onClick={()=> {
                  setClick(idx);
  
                  props.handleChange({
                    target: {
                      name: 'idStore',
                      value: arr.address.idStore
                    }
                  });
                  props.setStore(arr.address.idStore);

                  props.handleChange({
                    target: {
                      name: 'address',
                      value: arr.address.address
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'location',
                      value: arr.address.location
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'city',
                      value: arr.address.city
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'state',
                      value: arr.address.state
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'district',
                      value: arr.address.district
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'postalCode',
                      value: arr.address.postalCode
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'country',
                      value: arr.address.country
                    }
                  });

                  props.handleChange({
                    target: {
                      name: 'isInRange',
                      value: true
                    }
                  });

                  props.setUserInRange(true)
                  props.handleChange({
                    target: {
                      name: 'phoneNumber',
                      value: arr.phoneNumber
                    }
                  });

                }}>
                  <span><EnvironmentOutlined style={{color: click === idx ? '#91C48A' : '#979898'}}/> {intl.formatMessage({id: 'MERCHANT_SHIPMENT_ADDRESS'})} {idx + 1}</span>
                  -
                  <span className={'Content-address-card-body'}>{arr.address.state}, {arr.address.city}, {arr.address.district}, {arr.address.address}</span>
                </div>
              );
            }) : <Skeleton/>
            }
          </>
        </div>

        {loading ? <Button type="primary" className={'Content-address-card-button'} onClick={() => props.setShowShipment(false)}>+ { intl.formatMessage({id: 'MERCHANT_SHIPMENT_ADDRESS_NEW_DIRECTION'})}</Button>
          : <></>
        }
      </div>
    </form>
  )
}